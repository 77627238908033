<site-header class="mb-5 d-block"></site-header>

<!--==========================-->
<!-- Properise Search         -->
<!--==========================-->
<div class="propertise-search-section">
  <div class="container">
    <div class="p-search-content">
      <h3>For Lease</h3>
      <!-- Form -->
      <!-- 01 Row -->
      <div class="p-search-form d-flex">
        <div class="p-search-input p-search-col">
          <input type="text" name="search" placeholder="search by region, suburb or post code">
        </div>
        <div class="p-search-select blue-theme p-search-col mxw-204">
          <ng-select [items]="prop_parts" [(ngModel)]="propType" bindLabel="type" bindValue="id" [searchable]="false"
            [clearable]="false" placeholder="Type : House">
            <ng-template ng-label-tmp let-item="item">
              Type :
              {{item.type}}
            </ng-template>
          </ng-select>
        </div>
        <div class="p-search-select blue-theme p-search-col mxw-204">
          <ng-select [items]="prop_parts" [(ngModel)]="propBathrooms" bindLabel="bathrooms" bindValue="id"
            [searchable]="false" [clearable]="false" placeholder="Bathrooms : 2+">
            <ng-template ng-label-tmp let-item="item">
              Bathrooms :
              {{item.bathrooms}}
            </ng-template>
          </ng-select>
        </div>
        <div class="p-search-select blue-theme p-search-col mxw-204">
          <ng-select [items]="prop_parts" [(ngModel)]="propParking" bindLabel="parking" bindValue="parking"
            [searchable]="false" [clearable]="false" placeholder="Parking : 2+">
            <ng-template ng-label-tmp let-item="item">
              Parking :
              {{item.parking}}
            </ng-template>
          </ng-select>
        </div>

        <div class="p-search-button p-search-col">
          <input type="submit" name="search-btn" class="btn search-btn bg-blue" value="search">
        </div>

        <div class="p-search-button p-search-col mxw-48">
          <a href="javascript:void(0);" class="btn search-btn sm-btn active"><i class="fa fa-th-large"
              aria-hidden="true"></i></a>
        </div>

        <div class="p-search-button p-search-col mxw-48 d-none">
          <a href="javascript:void(0);" class="btn search-btn sm-btn"><i class="fa fa-bars" aria-hidden="true"></i></a>
        </div>

        <div class="p-search-button p-search-col mxw-48">
          <a href="javascript:void(0);" class="btn search-btn sm-btn"><i class="fa fa-map-marker"
              aria-hidden="true"></i></a>
        </div>
      </div>
      <!-- 02 Row -->
      <div class="p-search-form d-flex">
        <div class="p-search-select blue-theme p-search-col mxw-204">
          <ng-select [items]="prop_parts" [(ngModel)]="propPrice" bindLabel="price" bindValue="id" [searchable]="false"
            [clearable]="false" placeholder="Low : $100,000">
            <ng-template ng-label-tmp let-item="item">
              Low :
              {{item.price}}
            </ng-template>
          </ng-select>
        </div>
        <div class="p-search-select blue-theme p-search-col mxw-204">
          <ng-select [items]="prop_parts" [(ngModel)]="propPriceHigh" bindLabel="price" bindValue="id"
            [searchable]="false" [clearable]="false" placeholder="High : $200,000">
            <ng-template ng-label-tmp let-item="item">
              High :
              {{item.price}}
            </ng-template>
          </ng-select>
        </div>
        <div class="p-search-select blue-theme p-search-col mxw-204">
          <ng-select [items]="prop_parts" [(ngModel)]="propFilers" bindValue="id" bindLabel="filter"
            [searchable]="false" [clearable]="false" placeholder="please select filters">
            <ng-template ng-label-tmp let-item="item">
              <img width="13" src="assets/img/icons/filter-icon.png" alt="" />
              {{item.filter}}
            </ng-template>
          </ng-select>
        </div>
        <div class="p-search-button p-search-col mxw-88">
          <a href="javascript:void(0);" class="btn clear-btn">
            <i class="fa fa-times" aria-hidden="true"></i> reset
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<!--==========================-->
<!-- Properise List(saled)    -->
<!--==========================-->
<div class="propertise-grid-section pb-md-5">
  <div class="container">

    <div class="filters-info">
      <p>found 24 property. in miami</p>
      <div class="display">
        <span>display</span>
        <span>12 |</span>
        <span>24 |</span>
        <span>all</span>
      </div>
    </div>

    <ul class="props-grid">
      <li>
        <div class="prop-thumbnails props-thumbs-slider">
          <span class="prop-status bg-blue text-white">
            Just Listed
          </span>
          <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem class="slide">
              <img src="assets/img/props/prop-1.png" alt="">
            </div>
            <div ngxSlickItem class="slide">
              <img src="assets/img/props/prop-1.png" alt="">
            </div>
          </ngx-slick-carousel>
        </div>
        <div class="prop-content">
          <h3>
            <a href="/">Burlegh Heads</a>
          </h3>
          <p>14 James St</p>
          <span class="price">$890,900</span>
          <ul class="prop-metadata">
            <li>
              <i class="x-icon icon-bedrooms"></i>
              <div class="info">
                <p>4</p>
                <p>Bed</p>
              </div>
            </li>
            <li>
              <i class="x-icon icon-pool"></i>
              <div class="info">
                <p>1</p>
                <p>Pool</p>
              </div>
            </li>
            <li>
              <i class="x-icon icon-bathrooms"></i>
              <div class="info">
                <p>4</p>
                <p>Bath</p>
              </div>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <div class="prop-thumbnails props-thumbs-slider">
          <span class="prop-status bg-blue text-white">
            Just Listed
          </span>
          <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem class="slide">
              <img src="assets/img/props/prop-3.png" alt="">
            </div>
            <div ngxSlickItem class="slide">
              <img src="assets/img/props/prop-1.png" alt="">
            </div>
          </ngx-slick-carousel>
        </div>
        <div class="prop-content">
          <h3>
            <a href="/">Burlegh Heads</a>
          </h3>
          <p>14 James St</p>
          <span class="price">$890,900</span>
          <ul class="prop-metadata">
            <li>
              <i class="x-icon icon-bedrooms"></i>
              <div class="info">
                <p>4</p>
                <p>Bed</p>
              </div>
            </li>
            <li>
              <i class="x-icon icon-pool"></i>
              <div class="info">
                <p>1</p>
                <p>Pool</p>
              </div>
            </li>
            <li>
              <i class="x-icon icon-bathrooms"></i>
              <div class="info">
                <p>4</p>
                <p>Bath</p>
              </div>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <div class="prop-thumbnails props-thumbs-slider">
          <span class="prop-status bg-blue text-white">
            Just Listed
          </span>
          <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem class="slide">
              <img src="assets/img/props/prop-3.png" alt="">
            </div>
            <div ngxSlickItem class="slide">
              <img src="assets/img/props/prop-1.png" alt="">
            </div>
          </ngx-slick-carousel>
        </div>
        <div class="prop-content">
          <h3>
            <a href="/">Burlegh Heads</a>
          </h3>
          <p>14 James St</p>
          <span class="price">$890,900</span>
          <ul class="prop-metadata">
            <li>
              <i class="x-icon icon-bedrooms"></i>
              <div class="info">
                <p>4</p>
                <p>Bed</p>
              </div>
            </li>
            <li>
              <i class="x-icon icon-pool"></i>
              <div class="info">
                <p>1</p>
                <p>Pool</p>
              </div>
            </li>
            <li>
              <i class="x-icon icon-bathrooms"></i>
              <div class="info">
                <p>4</p>
                <p>Bath</p>
              </div>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <div class="prop-thumbnails props-thumbs-slider">
          <span class="prop-status bg-blue text-white">
            Just Listed
          </span>
          <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem class="slide">
              <img src="assets/img/props/prop-2.png" alt="">
            </div>
            <div ngxSlickItem class="slide">
              <img src="assets/img/props/prop-1.png" alt="">
            </div>
          </ngx-slick-carousel>
        </div>
        <div class="prop-content">
          <h3>
            <a href="/">Burlegh Heads</a>
          </h3>
          <p>14 James St</p>
          <span class="price">$890,900</span>
          <ul class="prop-metadata">
            <li>
              <i class="x-icon icon-bedrooms"></i>
              <div class="info">
                <p>4</p>
                <p>Bed</p>
              </div>
            </li>
            <li>
              <i class="x-icon icon-pool"></i>
              <div class="info">
                <p>1</p>
                <p>Pool</p>
              </div>
            </li>
            <li>
              <i class="x-icon icon-bathrooms"></i>
              <div class="info">
                <p>4</p>
                <p>Bath</p>
              </div>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <div class="prop-thumbnails props-thumbs-slider">
          <span class="prop-status bg-blue text-white">
            Just Listed
          </span>
          <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem class="slide">
              <img src="assets/img/props/prop-1.png" alt="">
            </div>
            <div ngxSlickItem class="slide">
              <img src="assets/img/props/prop-1.png" alt="">
            </div>
          </ngx-slick-carousel>
        </div>
        <div class="prop-content">
          <h3>
            <a href="/">Burlegh Heads</a>
          </h3>
          <p>14 James St</p>
          <span class="price">$890,900</span>
          <ul class="prop-metadata">
            <li>
              <i class="x-icon icon-bedrooms"></i>
              <div class="info">
                <p>4</p>
                <p>Bed</p>
              </div>
            </li>
            <li>
              <i class="x-icon icon-pool"></i>
              <div class="info">
                <p>1</p>
                <p>Pool</p>
              </div>
            </li>
            <li>
              <i class="x-icon icon-bathrooms"></i>
              <div class="info">
                <p>4</p>
                <p>Bath</p>
              </div>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <div class="prop-thumbnails props-thumbs-slider">
          <span class="prop-status bg-blue text-white">
            Just Listed
          </span>
          <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem class="slide">
              <img src="assets/img/props/prop-3.png" alt="">
            </div>
            <div ngxSlickItem class="slide">
              <img src="assets/img/props/prop-1.png" alt="">
            </div>
          </ngx-slick-carousel>
        </div>
        <div class="prop-content">
          <h3>
            <a href="/">Burlegh Heads</a>
          </h3>
          <p>14 James St</p>
          <span class="price">$890,900</span>
          <ul class="prop-metadata">
            <li>
              <i class="x-icon icon-bedrooms"></i>
              <div class="info">
                <p>4</p>
                <p>Bed</p>
              </div>
            </li>
            <li>
              <i class="x-icon icon-pool"></i>
              <div class="info">
                <p>1</p>
                <p>Pool</p>
              </div>
            </li>
            <li>
              <i class="x-icon icon-bathrooms"></i>
              <div class="info">
                <p>4</p>
                <p>Bath</p>
              </div>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <div class="prop-thumbnails props-thumbs-slider">
          <span class="prop-status bg-blue text-white">
            Just Listed
          </span>
          <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem class="slide">
              <img src="assets/img/props/prop-3.png" alt="">
            </div>
            <div ngxSlickItem class="slide">
              <img src="assets/img/props/prop-1.png" alt="">
            </div>
          </ngx-slick-carousel>
        </div>
        <div class="prop-content">
          <h3>
            <a href="/">Burlegh Heads</a>
          </h3>
          <p>14 James St</p>
          <span class="price">$890,900</span>
          <ul class="prop-metadata">
            <li>
              <i class="x-icon icon-bedrooms"></i>
              <div class="info">
                <p>4</p>
                <p>Bed</p>
              </div>
            </li>
            <li>
              <i class="x-icon icon-pool"></i>
              <div class="info">
                <p>1</p>
                <p>Pool</p>
              </div>
            </li>
            <li>
              <i class="x-icon icon-bathrooms"></i>
              <div class="info">
                <p>4</p>
                <p>Bath</p>
              </div>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <div class="prop-thumbnails props-thumbs-slider">
          <span class="prop-status bg-blue text-white">
            Just Listed
          </span>
          <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem class="slide">
              <img src="assets/img/props/prop-2.png" alt="">
            </div>
            <div ngxSlickItem class="slide">
              <img src="assets/img/props/prop-1.png" alt="">
            </div>
          </ngx-slick-carousel>
        </div>
        <div class="prop-content">
          <h3>
            <a href="/">Burlegh Heads</a>
          </h3>
          <p>14 James St</p>
          <span class="price">$890,900</span>
          <ul class="prop-metadata">
            <li>
              <i class="x-icon icon-bedrooms"></i>
              <div class="info">
                <p>4</p>
                <p>Bed</p>
              </div>
            </li>
            <li>
              <i class="x-icon icon-pool"></i>
              <div class="info">
                <p>1</p>
                <p>Pool</p>
              </div>
            </li>
            <li>
              <i class="x-icon icon-bathrooms"></i>
              <div class="info">
                <p>4</p>
                <p>Bath</p>
              </div>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <div class="prop-thumbnails props-thumbs-slider">
          <span class="prop-status bg-blue text-white">
            Just Listed
          </span>
          <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem class="slide">
              <img src="assets/img/props/prop-3.png" alt="">
            </div>
            <div ngxSlickItem class="slide">
              <img src="assets/img/props/prop-1.png" alt="">
            </div>
          </ngx-slick-carousel>
        </div>
        <div class="prop-content">
          <h3>
            <a href="/">Burlegh Heads</a>
          </h3>
          <p>14 James St</p>
          <span class="price">$890,900</span>
          <ul class="prop-metadata">
            <li>
              <i class="x-icon icon-bedrooms"></i>
              <div class="info">
                <p>4</p>
                <p>Bed</p>
              </div>
            </li>
            <li>
              <i class="x-icon icon-pool"></i>
              <div class="info">
                <p>1</p>
                <p>Pool</p>
              </div>
            </li>
            <li>
              <i class="x-icon icon-bathrooms"></i>
              <div class="info">
                <p>4</p>
                <p>Bath</p>
              </div>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <div class="prop-thumbnails props-thumbs-slider">
          <span class="prop-status bg-blue text-white">
            Just Listed
          </span>
          <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem class="slide">
              <img src="assets/img/props/prop-2.png" alt="">
            </div>
            <div ngxSlickItem class="slide">
              <img src="assets/img/props/prop-1.png" alt="">
            </div>
          </ngx-slick-carousel>
        </div>
        <div class="prop-content">
          <h3>
            <a href="/">Burlegh Heads</a>
          </h3>
          <p>14 James St</p>
          <span class="price">$890,900</span>
          <ul class="prop-metadata">
            <li>
              <i class="x-icon icon-bedrooms"></i>
              <div class="info">
                <p>4</p>
                <p>Bed</p>
              </div>
            </li>
            <li>
              <i class="x-icon icon-pool"></i>
              <div class="info">
                <p>1</p>
                <p>Pool</p>
              </div>
            </li>
            <li>
              <i class="x-icon icon-bathrooms"></i>
              <div class="info">
                <p>4</p>
                <p>Bath</p>
              </div>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <div class="prop-thumbnails props-thumbs-slider">
          <span class="prop-status bg-blue text-white">
            Just Listed
          </span>
          <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem class="slide">
              <img src="assets/img/props/prop-2.png" alt="">
            </div>
            <div ngxSlickItem class="slide">
              <img src="assets/img/props/prop-1.png" alt="">
            </div>
          </ngx-slick-carousel>
        </div>
        <div class="prop-content">
          <h3>
            <a href="/">Burlegh Heads</a>
          </h3>
          <p>14 James St</p>
          <span class="price">$890,900</span>
          <ul class="prop-metadata">
            <li>
              <i class="x-icon icon-bedrooms"></i>
              <div class="info">
                <p>4</p>
                <p>Bed</p>
              </div>
            </li>
            <li>
              <i class="x-icon icon-pool"></i>
              <div class="info">
                <p>1</p>
                <p>Pool</p>
              </div>
            </li>
            <li>
              <i class="x-icon icon-bathrooms"></i>
              <div class="info">
                <p>4</p>
                <p>Bath</p>
              </div>
            </li>
          </ul>
        </div>
      </li>
      <li class="none-hover text-center">
        <div class="pm-card bg-blue py-5">
          <div class="pm-card-content">
            <div class="pm-inner">
              <h2>Property Listings</h2>
              <h3>access our PREMIER property management service</h3>
              <p>Real Specialists have a reliable, dedicated and outcome driven team to help you protect your
                investment.</p>
              <div class="property-management-button">
                <button type="button" class="btn btn-ouline-blue slide-hvr">Get in Touch</button>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>

    <div class="text-center my-5">
      <a href="javascript:void(0);" class="btn btn-theme-blue px-5 slide-hvr">View More Properties</a>
    </div>
  </div>
</div>
<!--==========================-->
<!-- Get-in-Touch Section     -->
<!--==========================-->
<div class="get-in-touch-section py-5 bg-grey">
  <div class="container py-md-5">
    <div class="getin-tocuh-box my-md-5">
      <h3>Looking for off-market properties? </h3>
      <p>Access our premium off-market property list today. </p>
      <form>
        <div class="getintouch-form">
          <div class="form-group">
            <input type="email" class="light-input w-100" placeholder="Enter your email to get in touch with us">
          </div>
          <input type="submit" class="btn btn-theme-blue slide-hvr" value="Get In Touch">
        </div>
      </form>
    </div>
  </div>
</div>




<app-footer></app-footer>