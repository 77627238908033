*<site-header></site-header>

<div class="home-banner-v bg">
  <!-- Hidden -->
  <div class="container d-none">
    <div class="banner-content text-center">
      <h2>Connect with our <br> specialists today.</h2>
      <p>WANT TO KNOW THE VALUE OF YOUR PROPERTY?</p>
      <!-- Success Message -->
      <h4 class="alert alert-success mx-auto" style="max-width: 650px;" *ngIf="sendMail">
        Thank you for your enquiry, one of our specialist will be in contact soon.
      </h4>
      <!-- Form -->
      <div class="text-center">
        <input *ngIf="!isFocused" class="btn btn-theme-blue slide-hvr" name="search-btn" type="text"
          value="Find Out More" (click)="Oninputkeyword($event)">
      </div>
      <div class="header-banner-form" *ngIf="isFocused" [@slideInOut]>
        <form [formGroup]="mailForm" (ngSubmit)="onSubmit('Connect with our Specialists Enquiry')" class="enquiry-form">
          <div class="position-relative">
            <input type="text" placeholder="name" class="mt-4 banner-input rounded-tl-2" name="name" autocomplete="name"
              formControlName="name" placeholder="Please let us know your name" required
              [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
            <div *ngIf="submitted && f.name.errors" class="invalid-feedback d-block">
              <div *ngIf="f.name.errors.required">
                Name is required
              </div>
            </div>
          </div>
          <div class="other-fields mb-4">
            <div class="position-relative">
              <input type="tel" name="mobile" autocomplete="mobile" formControlName="mobile"
                placeholder="Enter your mobile" class="banner-input border-bottom-0 rounded-0" required
                [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }">
              <!-- check -->
              <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback d-block">
                <div *ngIf="f.mobile.errors.required">
                  Mobile is required
                </div>
              </div>
            </div>

            <div class="position-relative">
              <input type="email" name="email" autocomplete="email" formControlName="email"
                placeholder="Enter your email" required placeholder="Email" class="banner-input rounded-0"
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback d-block">
                <div *ngIf="f.email.errors.required">
                  Email is required
                </div>
                <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
              </div>
            </div>

            <div class="position-relative">
              <textarea class="banner-input rounded-0" name="message" autocomplete="message" formControlName="message"
                placeholder="Message" rows="1" required [ngClass]="{ 'is-invalid': submitted && f.message.errors }">
            </textarea>
              <!-- check -->
              <div *ngIf="submitted && f.message.errors" class="invalid-feedback d-block">
                <div *ngIf="f.message.errors.required">
                  Message is required
                </div>
              </div>
            </div>

            <button type="submit" class="sendreport-btn">
              SUBMIT
              <div class="spinner-border spinner-border-sm opacity-0" role="status" [ngClass]="{'opacity-1' : loading}">
                <span class="sr-only">Loading...</span>
              </div>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Header Video -->
  <!-- <div class="video-background">
    <img src="assets/img/video-play.png" alt="" (click)="playPause()" class="video-play-btn" *ngIf="isPauseClick">
    <video (click)="playPause()" id="header_video" #videoPlayer poster="assets/img/poster-slide.jpg">
      <source src="https://icentra.s3.ap-southeast-2.amazonaws.com/rs-files/assets/videos/REAL Specialists Intro.mp4" type="video/mp4" />
      Browser not supported
    </video>
  </div> -->
  <!-- *ngIf="properties && properties.length > 0" -->
  <ngx-slick-carousel class="carousel hero-slider m-0">
    <!-- (click)="goTo(p.id)" -->
    <div ngxSlickItem class="slide cp position-relative" *ngFor="let p of Staticproperties"
      [ngStyle]="{background: 'url(' + p.url + ')'}">
      <!-- <div class="overlay"></div> -->
      <div class="banner-content text-center position-relative">
        <h2>{{p.title}}</h2>
        <p>{{p.details}}</p>
        <!-- Form -->
        <div class="header-banner-form">
          <!-- <h4 class="alert alert-success mx-auto" style="max-width: 650px;" *ngIf="sendMail">
            Thank you for contact, one of our specialist will be in contact soon.
          </h4> -->
          <form [formGroup]="mailForm" class="enquiry-form"
            (ngSubmit)="onSubmit('Connect with our Specialists Enquiry')">
            <div class="position-relative">
              <input type="email" name="email" autocomplete="email" formControlName="email"
                placeholder="Enter your email" required class="banner-input rounded-0"
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" (keyup)="Oninputkeyword($event)">
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback d-block">
                <div *ngIf="f.email.errors.required">
                  Email is required
                </div>
                <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
              </div>
            </div>

            <div class="other-fields mb-4" *ngIf="isFocused" [@slideInOut]>

              <div class="position-relative">
                <input type="text" placeholder="name" [class.border-top-0]="isFocused"
                  [class.border-top-2]="submitted && f.name.errors" class=" banner-input rounded-tl-2 border-b-1"
                  name="name" autocomplete="name" formControlName="name" placeholder="Please let us know your name"
                  required [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                <div *ngIf="submitted && f.name.errors" class="invalid-feedback d-block">
                  <div *ngIf="f.name.errors.required">
                    Name is required
                  </div>
                </div>
              </div>

              <div class="position-relative">
                <input type="tel" name="mobile" autocomplete="mobile" formControlName="mobile"
                  placeholder="Enter your mobile" class="banner-input  rounded-0 border-b-1" required
                  [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }">
                <!-- check -->
                <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback d-block">
                  <div *ngIf="f.mobile.errors.required">
                    Mobile is required
                  </div>
                </div>
              </div>

              <div class="position-relative">
                <textarea class="banner-input rounded-0" name="message" autocomplete="message" formControlName="message"
                  placeholder="Message" rows="1" required [ngClass]="{ 'is-invalid': submitted && f.message.errors }">
              </textarea>
                <!-- check -->
                <div *ngIf="submitted && f.message.errors" class="invalid-feedback d-block">
                  <div *ngIf="f.message.errors.required">
                    Message is required
                  </div>
                </div>
              </div>

              <button type="submit" class="sendreport-btn">
                SUBMIT
                <div class="spinner-border spinner-border-sm opacity-0" role="status"
                  [ngClass]="{'opacity-1' : loading}">
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ngx-slick-carousel>
</div>
<!--====================-->
<!-- Services Section   -->
<!--====================-->
<div class="services-section">
  <div class="container container-1200">
    <div class="services-content">
      <h3 class="text-capitalize">How we can help you</h3>
    </div>
    <ul class="services-list">
      <li>
        <a routerLink="/residential/for-sale">
          <div class="service-thumbnail bg" style="background:url(assets/img/services/service-1.jpg)no-repeat;"></div>
          <div class="service-content">
            <a href="javascript:0;" routerLink="/residential/for-sale">Buy </a>
            <p>Browse through our extensive catalogue of properties for sale and find your next dream home.</p>
          </div>
        </a>
      </li>
      <li>
        <a routerLink="/residential/sell-with-us">
          <div class="service-thumbnail bg" style="background:url(assets/img/services/service-2.jpg)no-repeat;"></div>
          <div class="service-content">
            <a href="javascript:0;" routerLink="/residential/sell-with-us">Sell </a>
            <p>Take advantage of our full suite of services to reach more people and ensure that your home gets sold.
            </p>
          </div>
        </a>
      </li>
      <li>
        <a routerLink="/residential/for-lease">
          <div class="service-thumbnail bg" style="background:url(assets/img/services/service-3a.jpg)no-repeat;"></div>
          <div class="service-content">
            <a href="javascript:0;" routerLink="/residential/for-lease">Rent </a>
            <p>Search through our extensive catalogue and discover your next rental property.</p>
          </div>
        </a>
      </li>
      <li>
        <a routerLink="/property-management/manage-property">
          <div class="service-thumbnail bg" style="background:url(assets/img/services/service-4.png)no-repeat;"></div>
          <div class="service-content">
            <a href="javascript:0;" routerLink="/property-management/manage-property">Manage </a>
            <p>Take advantage of a personalised property management service that harnesses best in class practices to
              deliver outstanding results.</p>
          </div>
        </a>
      </li>
    </ul>
  </div>
</div>
<!--==========================-->
<!-- succes stories Section   -->
<!--==========================-->
<div class="stories-section d-none">
  <div class="container container-1200">
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
          <div ngxSlickItem class="slide">
            <img src="assets/img/success-stories.png" alt="" class="img-fluid">
          </div>
          <div ngxSlickItem class="slide">
            <img src="assets/img/success-stories.png" alt="" class="img-fluid">
          </div>
          <div ngxSlickItem class="slide">
            <img src="assets/img/success-stories.png" alt="" class="img-fluid">
          </div>
        </ngx-slick-carousel>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="stories-content px-md-5">
          <h3>Success stories</h3>
          <ngx-slick-carousel class="carousel" #slickModal2="slick-carousel" [config]="slideConfig2">
            <!-- 01 -->
            <div ngxSlickItem class="slide">
              <div class="price-time d-flex justify-content-between">
                <div class="text-left my-4">
                  <strong>$250K</strong>
                  <p>above list price</p>
                </div>
                <div class="text-left my-4">
                  <strong>4</strong>
                  <p>day on the market</p>
                </div>
              </div>
              <p>“The Real Specialists team helped us with the final touches of our home, allowing us to address those
                final issues that we were concerned about. The house sold quickly and we got above our target price.”
              </p>
              <div class="seller-info">
                <p>Hayley, Seller | Robina</p>
              </div>
            </div>
            <!-- 02 -->
            <div ngxSlickItem class="slide">
              <div class="price-time d-flex justify-content-between">
                <div class="text-left my-4">
                  <strong>$260K</strong>
                  <p>above list price</p>
                </div>
                <div class="text-left my-4">
                  <strong>4</strong>
                  <p>day on the market</p>
                </div>
              </div>
              <p>“The Real Specialists team helped us with the final touches of our home, allowing us to address those
                final issues that we were concerned about. The house sold quickly and we got above our target price.”
              </p>
              <div class="seller-info">
                <p>Hayley, Seller | Robina</p>
              </div>
            </div>
            <!-- 03 -->
            <div ngxSlickItem class="slide">
              <div class="price-time d-flex justify-content-between">
                <div class="text-left my-4">
                  <strong>$270K</strong>
                  <p>above list price</p>
                </div>
                <div class="text-left my-4">
                  <strong>4</strong>
                  <p>day on the market</p>
                </div>
              </div>
              <p>“The Real Specialists team helped us with the final touches of our home, allowing us to address those
                final issues that we were concerned about. The house sold quickly and we got above our target price.”
              </p>
              <div class="seller-info">
                <p>Hayley, Seller | Robina</p>
              </div>
            </div>
          </ngx-slick-carousel>

          <!-- Arrow -->
          <div class="agents-slider-arrow">
            <a href="javascript:void(0);" class="slider-arrow-outline blue-outline" (click)="next()">
              <i class="x-icon icon-sm-arrow-fill"></i>
            </a>
            <a href="javascript:void(0);" class="slider-arrow-outline active blue-outline sm" (click)="prev()">
              <i class="x-icon icon-md-arrow-fill"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--==========================-->
<!-- Concierge Section        -->
<!--==========================-->
<div class="concierge-section">
  <div class="container container-1200">
    <div class="row align-items-center">
      <div class="col-md-6">
        <div class="concierge-content">
          <h3 class="m-0"> A New Approach to Real Estate</h3>
          <p class="mt-4 mb-0">
            We want to establish a new approach to commercial real estate, where each of our specialists is their own
            boss and they are committed to anticipating your needs, nurturing relationships and becoming your lifelong
            property partner. </p>
          <p style="margin-top:10px;" class="mb-4">
            We’ve given our specialists access to leading technology, best-in-class marketing and learning programs, and
            support services to help them be more productive and generate greater value for our customers.
          </p>
          <!-- (click)="staticModal.show()" -->
          <a class="cp new-primary-btn" href="javascript:void(0);" (click)="scrollEnquiry(enquire)">
            <img src="assets/img/arronge-arrow.svg" alt="" class="mr-3">
            Find Out More
          </a>

        </div>
      </div>
      <div class="col-md-6 mt-md-0 mt-5">
        <div class="concierge-thumbnail">
          <img src="assets/img/Concierge.png" class="img-fluid" alt="">
        </div>
      </div>
    </div>
  </div>
</div>
<!--==========================-->
<!-- putting-people Section   -->
<!--==========================-->
<div class="puting-people-section">
  <div class="container container-1200">
    <h3>Putting People First</h3>
    <p>With 25 years in the industry and over $2 Billion made in sales throughout our operations, our experienced team
      knows
      how to deliver exceptional results whilst navigating the needs of our buyers and sellers. With you at the centre
      of our operations, our team works tirelessly to ensure that you receive the best service and enjoy the best
      results.</p>
  </div>
</div>
<!--==========================-->
<!-- special-state Section   -->
<!--==========================-->
<div class="special-state-section" style="background:url('assets/img/special-state.jpg')no-repeat;">
  <div class="container container-1200">
    <div class="row align-items-center">
      <div class="col-md-6">
        <h5>We have <span class="font-italic">specialists in every state,</span> so no matter where you are located - we
          have you covered.</h5>
      </div>
      <div class="col-md-6">
        <img src="assets/img/special-state-map.svg" alt="" class="img-fluid">
      </div>
    </div>
  </div>
</div>
<!--===============================-->
<!-- immediate-listing    Section  -->
<!--===============================-->
<div class="immediate-listing-section">
  <div class="container">
    <div class="row">
      <div class="col-md-6 d-flex flex-column align-items-center">
        <div class="immediate-thumb">
          <img src="assets/img/immediate-1.jpg" alt="" class="img-fluid">
        </div>
        <div class="immediate-content">
          <h3 class="text-capitalize">Immediate Listings</h3>
          <p class="pb-2">Our specialists are equipped with technology that allows them to quickly uncover market trends
            and generate bespoke marketing material in a matter of hours, shortening the time it takes for your property
            to be on market.</p>
          <a class="cp new-primary-btn mt-3" href="javascript:void(0);" (click)="scrollEnquiry(enquire)">
            <img src="assets/img/arronge-arrow.svg" alt="" class="mr-3 ">
            Enquire
          </a>
        </div>
      </div>
      <div class="col-md-6 d-flex flex-column align-items-center mt-5 mt-md-0">
        <div class="immediate-thumb">
          <img src="assets/img/immediate-2.jpg" alt="" class="img-fluid">
        </div>
        <div class="immediate-content">
          <h3 class="text-capitalize">Creating meaningful experiences</h3>
          <p class="pb-2">Through our technologies and people-first mindset, we're uncovering deeper industry insights
            and streamlining how our specialists generate value for our clients. </p>
          <a class="cp new-primary-btn mt-3" href="javascript:void(0);" (click)="scrollEnquiry(enquire)">
            <img src="assets/img/arronge-arrow.svg" alt="" class="mr-3 ">
            Enquire
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<!--==========================-->
<!-- putting-people Section   -->
<!--==========================-->
<div class="puting-people-section">
  <div class="container container-1200">
    <h3>Pioneering A New Approach</h3>
    <p>When you engage with one of our sales specialists, you’re dealing with a partner, not just an agent. By giving
      our specialists the freedom and support of our entire network, they have full responsibility and accountability
      for their business practices and operations which gives you greater clarity and flexibility in your real estate
      journey with us.</p>
  </div>
</div>
<!--====================-->
<!-- About Us Section   -->
<!--====================-->
<div class="aboutus-section">
  <div class="container">
    <div class="aboutus-inner">
      <div class="aboutus-img">
        <img src="assets//img/aboutus-bg.png" class="img-fluid">
      </div>
      <div class="aboutus-content">
        <h2>We believe that there's <span class="font-italic"> more to selling</span> <br> <span class="font-italic">and
            leasing</span> than just the sale</h2>
        <p>We’ve recognised a need for the ‘old way' of real estate to change, so we’re drawing on decades of industry
          experience to deliver a refined system that brings the industry's greatest innovators together to pioneer a
          new
          approach to real estate.</p>
      </div>
    </div>
  </div>
</div>
<!--===============================-->
<!-- New Experinces Form Section   -->
<!--===============================-->
<div class="experience-form-section" id="enquiry" style="background:url('assets/img/property-listing.jpg')no-repeat;">
  <div class="container">
    <div class="box-card">
      <h2>New Experiences Await</h2>
      <!-- <h4 class="alert alert-success mx-auto" style="max-width: 650px;" *ngIf="sendMail">
        Thank you for your enquiry, one of our specialist will be in contact soon.
      </h4> -->
      <form [formGroup]="mailForm2" (ngSubmit)="onSubmit2('New Experiences Await Enquiry')">
        <div class="new-ex-form pt-4">
          <div class="flex-grow-1 form-group">
            <input type="text" placeholder="name" class="site-input" name="name" autocomplete="name"
              formControlName="name" placeholder="Name" required
              [ngClass]="{ 'is-invalid': submitted && f2.name.errors }">
            <!-- check -->
            <div *ngIf="submitted2 && f2.name.errors" class="invalid-feedback d-block">
              <div *ngIf="f2.name.errors.required">
                Name is required
              </div>
            </div>
          </div>
          <div class="flex-grow-1 form-group">
            <input type="email" name="email" autocomplete="email" formControlName="email" placeholder="email" required
              placeholder="Email" class="site-input">
            <!-- check -->
            <div *ngIf="submitted2 && f2.email.errors" class="invalid-feedback d-block">
              <div *ngIf="f2.email.errors.required">
                Email is required
              </div>
              <div *ngIf="f2.email.errors.email">Email must be a valid email address</div>
            </div>
          </div>
          <div class="flex-grow-1 form-group">
            <input type="tel" name="mobile" autocomplete="mobile" formControlName="mobile" placeholder="mobile" required
              placeholder="Mobile" class="site-input">
            <!-- check -->
            <div *ngIf="submitted2 && f2.mobile.errors" class="invalid-feedback d-block">
              <div *ngIf="f2.mobile.errors.required">
                Mobile is required
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 form-group">
          <textarea type="text" rows="10" cols="5" class="site-input" name="message" autocomplete="message"
            formControlName="message" placeholder="Message" required></textarea>
          <!-- check -->
          <div *ngIf="submitted2 && f2.message.errors" class="invalid-feedback d-block">
            <div *ngIf="f2.message.errors.required">
              Message is required
            </div>
          </div>
        </div>
        <div class="form-group text-right">
          <input type="submit" name="submit" value="Submit" class="btn mt-1 px-4 btn-theme-blue" [disabled]="loading">
        </div>
      </form>
    </div>
  </div>
</div>
<app-footer></app-footer>
<!-- ============= -->
<!-- Model         -->
<!-- ============= -->
<!-- <div class="modal fade" bsModal #staticModal="bs-modal" tabindex="-1" role="dialog"
  aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="staticModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="text-center px-4 mb-2 d-none">
          <h4>Would you like one of our Property Specialists to contact you about this property?</h4>
        </div>
        <div class="widget-body">
          <h4 class="alert alert-success mx-auto" style="max-width: 650px;" *ngIf="sendMail">
            Thank you for your message, someone will be contact as soon as possible.
          </h4>
          <form [formGroup]="mailForm" (ngSubmit)="onSubmit('Value of Property Enquiry')" class="enquiry-form"
            *ngIf="!sendMail">

            <div class="form-group">
              <input type="text" placeholder="name" class="site-input" name="name" autocomplete="name"
                formControlName="name" placeholder="Name" required
                [ngClass]="{ 'is-invalid': submitted && f.name.errors }">

              <div *ngIf="submitted && f.name.errors" class="invalid-feedback d-block">
                <div *ngIf="f.name.errors.required">
                  Name is required
                </div>
              </div>
            </div>

            <div class="form-group">
              <input type="email" name="email" autocomplete="email" formControlName="email" placeholder="email" required
                placeholder="Email" class="site-input">

              <div *ngIf="submitted && f.email.errors" class="invalid-feedback d-block">
                <div *ngIf="f.email.errors.required">
                  Email is required
                </div>
                <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
              </div>
            </div>

            <div class="form-group">
              <input type="tel" name="mobile" autocomplete="mobile" formControlName="mobile" placeholder="mobile"
                required placeholder="Mobile" class="site-input">

              <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback d-block">
                <div *ngIf="f.mobile.errors.required">
                  Mobile is required
                </div>
              </div>
            </div>

            <div class="form-group">
              <textarea class="site-input" name="message" autocomplete="message" formControlName="message"
                placeholder="Message" required></textarea>

              <div *ngIf="submitted && f.message.errors" class="invalid-feedback d-block">
                <div *ngIf="f.message.errors.required">
                  Message is required
                </div>
              </div>
            </div>

            <div class="form-group">
              <re-captcha formControlName="recaptchaReactive"></re-captcha>
            </div>

            <div class="form-group">
              <input type="submit" value="Submit" [disabled]="mailForm.invalid"
                class="btn btn-theme-blue slide-hvr w-100">
            </div>


          </form>
        </div>
      </div>
    </div>
  </div>
</div> -->




<!-- Error Modal -->
<!-- Button trigger modal -->
<button #submitModalTriggerBtn type="button" hidden="true" class="btn btn-primary" data-toggle="modal"
  data-target="#errorModal">
  Launch demo modal
</button>

<!-- Modal -->
<div class="modal fade" id="errorModal" tabindex="-1" aria-labelledby="errorModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="errorModalLabel"></h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h4 class="alert alert-success mx-auto text-center" style="max-width: 650px;">
          Thank you for contact, one of our specialist will be in contact soon.
        </h4>
      </div>
    </div>
  </div>
</div>