<footer>
  <div class="footer-upper">
    <div class="container">
      <div class="footer-row d-flex align-items-center justify-content-between">
        <div class="footer-logo py-2">
          <a href="javascript:void(0);" routerLink="/">
            <img src="assets/img/RS-logo-white.png" alt="" width="200px">
          </a>
        </div>
        <div class="footer-social-links">
          <ul>
            <li><a target="_blank" href="https://www.facebook.com/RSRealSpecialists"><i class="fa fa-facebook"></i></a>
            </li>
            <li><a target="_blank" href="https://www.linkedin.com/company/38103403"><i class="fa fa-linkedin"></i></a>
            </li>
            <li><a target="_blank" href="https://www.instagram.com/realspecialists"><i class="fa fa-instagram"></i></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- Middle -->
  <div class="footer-middle">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <div class="footer-widget">
            <h3>SERVICES</h3>
            <ul class="footer-menu">
              <li><a href="javascript:void(0);" routerLink="/residential/sell-with-us">Sell With Us</a></li>
              <li><a href="javascript:void(0);" routerLink="/residential/for-sale">For Sale</a></li>
              <li><a href="javascript:void(0);" routerLink="/residential/sold-by-us">Sold By Us</a></li>
              <li><a href="javascript:void(0);" routerLink="/residential/for-lease">For Lease </a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-2">
          <div class="footer-widget">
            <h3>ABOUT US</h3>
            <ul class="footer-menu">
              <li><a href="javascript:void(0);" routerLink="/our-approach">Our Approach</a></li>
              <li><a href="javascript:void(0);" routerLink="/agents">Our Agents</a></li>
              <li><a href="javascript:void(0);" routerLink="/news">News & Insights</a></li>
              <li><a href="javascript:void(0);" routerLink="/refund-policy">Refund Policy</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-2">
          <div class="footer-widget">
            <h3>TRADITIONS</h3>
            <ul class="footer-menu">
              <li><a href="javascript:void(0);" routerLink="/flexible-agent">Culture</a></li>
              <li><a href="javascript:void(0);" routerLink="/technologies-and-collaboration">Technologies &
                  Collaboration</a>
              </li>
              <li><a href="javascript:void(0);" routerLink="/join-as-agent">Work with Us</a></li>
              <li><a href="javascript:void(0);" routerLink="/contact">Our Offices</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-4">
          <div class="footer-widget">
            <h3>REALSPECIALISTS</h3>
            <p>We’ve given our specialists access to leading technology, best-in-class marketing and learning programs,
              and support services to help them be more productive and generate greater value for you.</p>
            <div class="footer-info">
              <div class="text-left"><i class="fa fa-share"></i> PO Box 1331, Coolangatta, QLD, 4225</div>
              <div class="text-left"><i class="fa fa-phone"></i> (07) 5535 0500</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Bottom -->
  <div class="footer-bottom">
    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col">
          <p>Copyright @{{this.currYear}} Real Specialists </p>
          <p style="padding: 0px 0px 10px;">Web Design - <a href="https://xenex-media.com.au/" target="_blank">Xenex
              Media</a></p>
        </div>
        <div class="col-md-4">
          <div class="footer-bottom-links text-white">
            <a href="javascript:0;" routerLink="/privacy-policy">Privacy Policy </a> <span>|</span>
            <a href="javascript:0;" routerLink="/terms-conditions">Terms and Conditions </a> <span>|</span>
            <a href="javascript:0;" routerLink="/contact">Contact Us</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>