import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
} from "@angular/core";
import { trigger, transition, animate, style } from "@angular/animations";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute, Params } from "@angular/router";

import { SlickCarouselComponent } from "ngx-slick-carousel";
import { ChartDataSets, ChartOptions } from "chart.js";
import { Color, BaseChartDirective, Label } from "ng2-charts";

import { ApiService } from "../../../../api.service";
import { first } from "rxjs/operators";
import { IcentraService } from "src/app/services/icentra.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],

  animations: [
    trigger("slideInOut", [
      transition(":enter", [
        style({ transform: "translateY(-2%)" }),
        animate("10ms ease-in", style({ transform: "translateY(0%)" })),
      ]),
      transition(":leave", [
        animate("10ms ease-in", style({ transform: "translateY(-2%)" })),
      ]),
    ]),
  ],
})
export class HomeComponent implements OnInit {
  @ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef;
  @ViewChild("videoPlayer", { static: false }) elementView: ElementRef;

  @ViewChild("slickModal", { static: true }) slickModal: SlickCarouselComponent;
  @ViewChild("slickModal2", { static: true })
  @ViewChild("slickModal3", { static: true })
  slickModal2: SlickCarouselComponent;
  slickModal3: SlickCarouselComponent;

  @ViewChild("submitModalTriggerBtn", {
    read: ElementRef,
    static: false,
  })
  submitModalTriggerBtn: ElementRef<HTMLElement>;

  isPlay: boolean = false;
  isPauseClick: boolean = true;
  videoPlayer: number;

  enquire: any;
  isFocused = false;
  mailForm: FormGroup;
  mailForm2: FormGroup;
  loading = false;
  loading2 = false;
  submitted = false;
  submitted2 = false;
  sendMail = false;
  sendMail2 = false;
  TotalUsers = 0;
  TotalProps = 0;
  properties = [];
  Staticproperties = [];
  propsLoad = false;
  constructor(
    private IC: IcentraService,
    private api: ApiService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.Staticproperties = [
      {
        id: 1,
        title: "Reinventing Real Estate",
        details: "Find out what your property is worth",
        url: "assets/img/new-banners/1.jpg",
      },
      {
        id: 2,
        title: "Reinventing Real Estate",
        details: "Find out what your property is worth",
        url: "assets/img/new-banners/2.jpg",
      },
      {
        id: 3,
        title: "Reinventing Real Estate",
        details: "Find out what your property is worth",
        url: "assets/img/new-banners/3.jpg",
      },
      {
        id: 4,
        title: "Reinventing Real Estate",
        details: "Find out what your property is worth",
        url: "assets/img/new-banners/4.jpg",
      },
      {
        id: 5,
        title: "Reinventing Real Estate",
        details: "Find out what your property is worth",
        url: "assets/img/new-banners/5.jpg",
      },
      {
        id: 6,
        title: "Reinventing Real Estate",
        details: "Find out what your property is worth",
        url: "assets/img/new-banners/6.jpg",
      },
      {
        id: 7,
        title: "Reinventing Real Estate",
        details: "Find out what your property is worth",
        url: "assets/img/new-banners/7.jpg",
      },
    ];
  }
  // jodie.hergenhan@realspecialsts.com
  ngOnInit() {
    this.mailForm = this.formBuilder.group({
      // address: ["address", [Validators.required]],
      name: ["", [Validators.required]],
      to: ["rsforms@xenex-media.com.au", [Validators.required]],
      mobile: ["", []],
      message: ["", [Validators.required]],
      title: [""],
      email: ["", [Validators.required, Validators.email]],
      // recaptchaReactive: [null, [Validators.required]],
    });
    this.mailForm2 = this.formBuilder.group({
      // address: ["address", [Validators.required]],
      name: ["", [Validators.required]],
      to: ["rsforms@xenex-media.com.au", [Validators.required]],
      mobile: ["", []],
      message: ["", [Validators.required]],
      title: [""],
      email: ["", [Validators.required, Validators.email]],
      // recaptchaReactive: [null, [Validators.required]],
    });
    this.getTotals();
    this.propsLoad = false;
    this.api
      .get("eagle/residential/sale-properties", {
        suburb: "Kingscliff",
        limit: 5,
      })
      .subscribe((res) => {
        res.data.forEach((item) => {
          this.properties.push(item);
        });
        this.propsLoad = true;
      });
  }

  playPause() {
    var myVideo: any = document.getElementById("header_video");
    if (myVideo.paused) myVideo.play();
    else myVideo.pause();
    if (this.isPauseClick) {
      this.isPauseClick = !this.isPauseClick;
    } else {
      this.isPauseClick = true;
    }
  }
  @HostListener("window:scroll", ["$event"])
  onWindowScroll($event) {
    var myVideo: any = document.getElementById("header_video");
    var top = window.pageYOffset;
    if (top > this.videoPlayer) {
      myVideo.pause();
      this.isPauseClick = true;
    }
  }
  getTotals() {
    this.IC.getData().subscribe((re) => {
      this.TotalUsers = re["data"].length;
    });
    this.api.get("eagle/getTotalProps").subscribe((tt) => {
      this.TotalProps = tt.total;
    });
  }
  /*
   *
   *
   * 00 Banner Form
   * Slider (SUCCESS STORIES)
   */
  Oninputkeyword(e) {
    this.sendMail = false;
    if (e.target.value) {
      this.isFocused = true;
    } else {
      this.isFocused = false;
    }
  }
  // Slider
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    // autoplay: true,
    autoplaySpeed: 3000,
    speed: 2000,
  };
  slideConfig2 = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 2000,
    vertical: true,
    verticalSwiping: true,
  };
  next() {
    this.slickModal.slickNext();
    this.slickModal2.slickNext();
  }
  prev() {
    this.slickModal.slickPrev();
    this.slickModal2.slickPrev();
  }

  /*
   *
   *
   * 01 Mail Form
   */
  get f() {
    return this.mailForm.controls;
  }
  get f2() {
    return this.mailForm2.controls;
  }
  onSubmitAUTO(title = "", ev) {
    if (ev && ev.keyCode == 13) {
      this.mailForm.controls["title"].setValue(title);
      this.mailForm.controls["name"].setValue(ev.target.value);
      this.mailForm.controls["message"].setValue(ev.target.value);
      this.mailForm.controls["recaptchaReonSubmitactive"].setValue(1);
      this.mailForm.controls["mobile"].setValue("N/A");
      this.onSubmit(title);
    }
  }
  onSubmit(title = "") {
    this.mailForm.controls["title"].setValue(title);
    this.mailForm.controls["to"].setValue("rsforms@xenex-media.com.au");
    // this.mailForm.controls["recaptchaReactive"].setValue(1);
    this.submitted = true;
    if (this.mailForm.invalid) {
      return;
    }
    this.loading = true;
    this.api
      .post("rex/form/ToEmail", this.mailForm.value)
      .pipe(first())
      .subscribe(
        (data) => {
          this.loading = false;
          this.submitted = false;
          this.mailForm.reset();
          this.isFocused = false;
          this.sendMail = true;

          this.clickButtonModal();
        },
        (error) => {
          this.loading = false;
        }
      );
  }
  onSubmit2(title = "") {
    this.mailForm2.controls["title"].setValue(title);
    this.mailForm2.controls["to"].setValue("rsforms@xenex-media.com.au");
    // this.mailForm2.controls["recaptchaReactive"].setValue(1);
    this.submitted2 = true;
    if (this.mailForm2.invalid) {
      return;
    }
    this.loading2 = true;
    this.api
      .post("rex/form/ToEmail", this.mailForm2.value)
      .pipe(first())
      .subscribe(
        (data) => {
          this.loading2 = false;
          this.submitted2 = false;
          this.mailForm2.reset();
          this.isFocused = false;
          this.sendMail2 = true;

          this.clickButtonModal();
        },
        (error) => {
          this.loading2 = false;
        }
      );
  }

  scrollEnquiry(el) {
    document.getElementById("enquiry").scrollIntoView();
  }

  /*
   *
   *
   * 03 Chart JS
   */
  public lineChartType = "line";
  public lineChartLabels = ["2010", "2012", "2015", "2018", "2020"];
  public lineChartData = [
    {
      data: [1250, 2500, 5000, 6250, 7500, 8750],
    },
  ];
  public lineChartOptions: ChartOptions = {
    responsive: true,
    legend: { display: false },

    scales: {
      xAxes: [
        {
          ticks: {
            autoSkip: false,
            maxRotation: 0,
            minRotation: 0,
          },
          gridLines: { display: false },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
  public lineChartColors = [
    {
      backgroundColor: "rgba(0, 0, 0, 0)",
      borderColor: "#e45e39",
      pointBackgroundColor: "#e45e39",
      pointBorderColor: "#e45e39",
      pointHoverBackgroundColor: "#e45e39",
      pointHoverBorderColor: "#e45e39",
    },
  ];
  getImagesFromArray(arr) {
    if (arr && arr[0]) {
      arr.sort(function (a, b) {
        return a["position"] - b["position"];
      });
      return arr[0].url.replace("http://", "https://");
    }
    return "https://dummyimage.com/300";
  }
  goTo(ID) {
    this.router.navigate([]).then((result) => {
      window.open("/property/" + ID, "_blank");
    });
  }
  clickButtonModal() {
    let el: HTMLElement = this.submitModalTriggerBtn.nativeElement;
    el.click();
  }
}
