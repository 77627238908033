<site-header></site-header>

<div class="home-banner bg" style="background:url('assets/img/banners/our-approach.jpg')no-repeat;">
  <div class="container">
    <div class="banner-content text-center">
      <h2>Our Approach</h2>
      <p>We are all about bringing change to the traditional approach to real estate.</p>
    </div>
  </div>
</div>
<!--==========================-->
<!-- Our Approaches Section   -->
<!--==========================-->
<div class="our-approaches-section py-5">
  <div class="container pb-md-5">
    <div class="approaches-header">
      <h3>We’ve recognised a need for change in the market and have stepped up to modernise the buy and sell experience.
      </h3>
    </div>
    <div class="approaches-body">
      <div class="row align-items-center mb-5">
        <div class="col-md-6">
          <h4 class="heading--border-b left">Agent And Customer Focus </h4>
          <p>We operate on the core belief that good people make great specialists. That’s why we spare no effort in
            finding the industry’s brightest and giving them the necessary support and leadership frameworks to ensure
            their continued growth and success. </p>
          <p>Because we have the best specialists, we’re confident that we can offer our customers the best real estate
            experiences, service and value. </p>
        </div>
        <div class="col-md-6">
          <img src="assets/img/approaches/1.png" alt="" class="img-fluid">
        </div>
      </div>
      <div class="row align-items-center flex-column-reverse flex-md-row">
        <div class="col-md-6 mt-3 mb-mt-0">
          <img src="assets/img/approaches/2.png" alt="" class="img-fluid">
        </div>
        <div class="col-md-6">
          <h4 class="heading--border-b left">Next-Generation Technology </h4>
          <p>We are always looking for ways to improve our approach and deliver greater value to our customers. That's
            why our technologies are constantly pushing the boundaries of what is possible when it comes to analysing
            the market and uncovering actionable insights.</p>
          <p>We are always pioneering new methods of communication and collaboration to ensure that we are always
            maximising the value that we deliver to our customers.</p>
        </div>
      </div>
    </div>
  </div>
</div>
<!--===============================-->
<!-- Innovative Marketing Section   -->
<!--===============================-->
<div class="innovative-maketing-section">
  <div class="bg-grey over-bottom-area"></div>
  <div class="container">
    <div class="innovative-header pt-5">
      <div class="row">
        <div class="col-md-12 py-4">
          <h4 class="text-center heading--border-b">Innovative Marketing</h4>
        </div>
        <div class="col-lg-5 col-md-12 mb-md-5 mb-3">
          <h2>We’re dedicated to giving your <br> property the attention it deserves. </h2>
        </div>
        <div class="col-lg-7 col-md-12 mb-md-5 mb-3">
          <p>Our dedication to innovation doesn’t stop at technology, we are always on the hunt for greater ways to
            reach out to more audiences and tell them about your property. From our superior signage, photos and media
            to our unique approach to marketing,</p>
        </div>
      </div>
    </div>
    <div class="innovative-maketing-overlay">
      <div class="video">
        <img src="assets/img/approaches/innovative-marketing.png" alt="" class="img-fluid">
      </div>
    </div>
  </div>
</div>
<!--==========================-->
<!-- Get-in-Touch Section     -->
<!--==========================-->
<div class="get-in-touch-section py-5 bg-grey d-none">
  <div class="container py-md-5">
    <div class="getin-tocuh-box my-md-5">
      <h3>Find Your Specialist</h3>
      <p>Receive expert device, paired with top-notch marketing from our Real Specialist experts. Backed by data-driven
        software, our agents spend less time at their desk and more time where it matters. </p>
      <form>
        <div class="getintouch-form">
          <div class="form-group">
            <label>Find the expert for your area</label>
            <input type="email" class="light-input w-100" placeholder="Enter your suburb">
          </div>
          <input type="submit" class="btn blue-outline mh-35 slide-hvr" value="Find Agents">
        </div>
      </form>
    </div>
  </div>
</div>


<app-footer></app-footer>