<!-- Sidebar Menu -->
<div class="left-sidebar bg-white" [class.in]="MenuCollapsed">
  <div class="app-brand">
    <a routerLink="/">
      <img src="assets/img/RS-logo-blue.png" alt="" class="img-fluid">
    </a>
  </div>
  <ul class="nav sidebar-menu" id="sidebar-menu">
    <li class="has-sub" routerLinkActive="active">
      <a aria-expanded="false" class="sidenav-item-link collapsed" data-target="#services" data-toggle="collapse"
        href="javascript:void(0)">
        <span class="nav-text">Our Services</span>
        <i class="fa fa-angle-right pull-right"></i>
      </a>
      <ul class="collapse" id="services" data-parent="#sidebar-menu" routerLinkActive="show">
        <div class="sub-menu">
          <li><a routerLinkActive="active" routerLink="/residential/sell-with-us">SELL WITH US</a></li>
          <li><a routerLinkActive="active" routerLink="/residential/for-sale">FOR SALE</a></li>
          <li><a routerLinkActive="active" routerLink="/residential/sold-by-us">SOLD BY US</a></li>
          <li><a routerLinkActive="active" routerLink="/residential/for-lease">FOR LEASE</a></li>
        </div>
      </ul>
    </li>
    <li class="has-sub" routerLinkActive="active">
      <a aria-expanded="false" class="sidenav-item-link collapsed" data-target="#propertym" data-toggle="collapse"
        href="javascript:void(0)">
        <span class="nav-text">Property Management</span>
        <i class="fa fa-angle-right pull-right"></i>
      </a>
      <ul class="collapse" id="propertym" data-parent="#sidebar-menu" routerLinkActive="show">
        <div class="sub-menu">
          <li>
            <a href="javascript:void(0);">Property Management</a>
          </li>
          <li class="has-sub" routerLinkActive="active">
            <a aria-expanded="false" class="sidenav-item-link collapsed" data-target="#propertym_prop"
              data-toggle="collapse" href="javascript:void(0);">
              <span class="nav-text">Manage Property</span>
              <i class="fa fa-angle-right pull-right"></i>
            </a>
            <ul class="collapse" id="propertym_prop" data-parent="#propertym" routerLinkActive="show">
              <div class="sub-menu">
                <li><a routerLinkActive="active" routerLink="/property-management/manage-property">Manage My
                    property</a></li>
                <li><a routerLinkActive="active" routerLink="/property-management/rental-appraisal">Rental
                    Appraisals</a></li>
              </div>
            </ul>
          </li>
          <li class="has-sub" routerLinkActive="active">
            <a aria-expanded="false" class="sidenav-item-link collapsed" data-target="#propertym_sub"
              data-toggle="collapse" href="javascript:void(0);">
              <span class="nav-text">Our Properties For Lease</span>
              <i class="fa fa-angle-right pull-right"></i>
            </a>
            <ul class="collapse" id="propertym_sub" data-parent="#propertym" routerLinkActive="show">
              <div class="sub-menu">
                <li><a routerLinkActive="active" routerLink="/residential/search-for-lease">Search for a
                    property</a></li>
                <li><a routerLinkActive="active" routerLink="/properties-for-lease/application-form">Application
                    Forms</a></li>
              </div>
            </ul>
          </li>
          <li class="has-sub" routerLinkActive="active">
            <a aria-expanded="false" routerLink="/tenant" routerLinkActive="active" class="sidenav-item-link collapsed"
              data-target="#propertym_tenant" data-toggle="collapse" href="javascript:void(0);">
              <span class="nav-text">I am a tenant</span>
              <i class="fa fa-angle-right pull-right"></i>
            </a>
            <ul class="collapse" id="propertym_tenant" data-parent="#propertym" routerLinkActive="show">
              <div class="sub-menu">
                <li><a routerLinkActive="active" routerLink="/tenant/induction">Induction</a></li>
                <li><a routerLinkActive="active" routerLink="/tenant/portal">portal</a></li>
                <li><a routerLinkActive="active" routerLink="/tenant/maintenance">Maintenance</a></li>
                <li class="d-none"><a routerLinkActive="active" routerLink="/tenant/top-tips">Top Tips
                    for tenants</a></li>
                <li><a routerLinkActive="active" routerLink="/tenant/payments">Payments</a></li>
                <li><a routerLinkActive="active" routerLink="/tenant/vacating">Vacating</a></li>
                <li><a routerLinkActive="active" routerLink="/tenant/request-form-documents">Request
                    forms & documents</a></li>
              </div>
            </ul>
          </li>
          <li class="has-sub" routerLinkActive="active">
            <a aria-expanded="false" routerLink="/owner" routerLinkActive="active" class="sidenav-item-link collapsed"
              data-target="#propertym_owner" data-toggle="collapse" href="javascript:void(0);">
              <span class="nav-text">owner</span>
              <i class="fa fa-angle-right pull-right"></i>
            </a>
            <ul class="collapse" id="propertym_owner" data-parent="#propertym" routerLinkActive="show">
              <div class="sub-menu">
                <li><a routerLinkActive="active" routerLink="/owner/portal">portal</a></li>
                <li><a routerLinkActive="active" routerLink="/owner/maintenance">Maintenance</a></li>
                <li><a routerLinkActive="active" routerLink="/owner/request-sales-appraisal">Request a
                    sales appraisal</a></li>
              </div>
            </ul>
          </li>
        </div>
      </ul>
    </li>
    <li>
      <a class="sidenav-item-link" routerLinkActive="active" href="http://realspecialistscommercial.com/">
        <span class="nav-text">Commercial</span>
      </a>
    </li>
    <li class="has-sub" routerLinkActive="active">
      <a aria-expanded="false" class="sidenav-item-link collapsed" data-target="#story" data-toggle="collapse"
        href="javascript:void(0)">
        <span class="nav-text">OUR STORY</span>
        <i class="fa fa-angle-right pull-right"></i>
      </a>
      <ul class="collapse" id="story" data-parent="#sidebar-menu" routerLinkActive="show">
        <div class="sub-menu">
          <li><a routerLinkActive="active" routerLink="/about-us">About us</a></li>
          <li><a routerLinkActive="active" routerLink="/agents">OUR AGENTS</a></li>
          <li><a routerLinkActive="active" routerLink="/news">NEWS & INSIGHTS</a></li>
          <li><a routerLinkActive="active" routerLink="/our-approach">OUR APPROACH</a></li>
          <!-- <li><a routerLinkActive="active" routerLink="/rpm">RPM</a></li> -->
          <li><a routerLinkActive="active" routerLink="/articles">Articles</a></li>
        </div>
      </ul>
    </li>
    <li class="has-sub" routerLinkActive="active">
      <a aria-expanded="false" class="sidenav-item-link collapsed" data-target="#joinus" data-toggle="collapse"
        href="javascript:void(0)">
        <span class="nav-text">Join us</span>
        <i class="fa fa-angle-right pull-right"></i>
      </a>
      <ul class="collapse" id="joinus" data-parent="#sidebar-menu" routerLinkActive="show">
        <div class="sub-menu">
          <li><a routerLinkActive="active" routerLink="/join-as-agent">JOIN AS AN AGENT</a></li>
          <li><a routerLinkActive="active" routerLink="/join-as-agency">JOIN AS AN AGENCY</a></li>
          <li><a routerLinkActive="active" routerLink="/flexible-agent">FLEXIBLE AGENT MODELS</a></li>
          <li><a routerLinkActive="active" routerLink="/branding-and-equity">branding & equity</a></li>
          <li><a routerLinkActive="active" routerLink="/hubs-and-scale">our hubs and scale</a></li>
          <li><a routerLinkActive="active" routerLink="/technologies-and-collaboration">Technologies &
              Collaboration</a></li>
          <li><a routerLinkActive="active" routerLink="/employment-opportunities">
              Employment Opportunities
            </a>
          </li>
        </div>
      </ul>
    </li>
    <li class="has-sub" routerLinkActive="active">
      <a aria-expanded="false" class="sidenav-item-link collapsed" data-target="#contactus" data-toggle="collapse"
        href="javascript:void(0)">
        <span class="nav-text">Contact</span>
        <i class="fa fa-angle-right pull-right"></i>
      </a>
      <ul class="collapse" id="contactus" data-parent="#sidebar-menu" routerLinkActive="show">
        <div class="sub-menu">
          <li><a routerLinkActive="active" routerLink="/contact">Get in Touch</a></li>
          <li><a routerLinkActive="active" routerLink="/agents">Our Agents</a></li>
        </div>
      </ul>
    </li>
  </ul>
</div>