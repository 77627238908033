import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private GlobalMenuCollapsed: BehaviorSubject<boolean> = new BehaviorSubject(false);
  cartToggled = this.GlobalMenuCollapsed.asObservable();


  constructor(private http: HttpClient) { }

  setcartToggled = (value: boolean) => {
    this.GlobalMenuCollapsed.next(value);
  }


  post(url, data) {
    return this.http.post(`${environment.rsApi}` + url, data);
  }
  get(url, params = null): Observable<any> {
    return this.http.get(`${environment.rsApi}` + url, { params });
  }

  paywaypayment(data) {
    return this.http.post(`${environment.rsApi}` + 'payway/payment', data);
  }
}
