import { Component, OnInit } from "@angular/core";
import { IcentraService } from "src/app/services/icentra.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";
import { ApiService } from "../../../../../../api.service";

@Component({
  selector: "app-employment-opportunities",
  templateUrl: "./employment-opportunities.component.html",
  styleUrls: ["./employment-opportunities.component.css"],
})
export class EmploymentOpportunitiesComponent implements OnInit {
  constructor(
    private api: ApiService,
    private formBuilder: FormBuilder,
    private IC: IcentraService
  ) {}

  sendMail = false;

  mailForm: FormGroup;
  loading = false;
  submitted = false;

  ngOnInit() {
    this.mailForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      mobile: ["", []],
      title: ["", []],
      message: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      website: ["", [Validators.required]],
      resume: ["", [Validators.required]],
      linkedin_profile: ["", [Validators.required]],
      recaptchaReactive: [null, [Validators.required]],
    });
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files.length) {
      const fileReader: FileReader = new FileReader();
      fileReader.readAsDataURL(event.target.files[0]);
      fileReader.onload = (event: Event) => {
        this.mailForm.patchValue({
          resume: fileReader.result,
        });
      };
    }
  }

  onSubmit(title = "") {
    this.mailForm.controls["title"].setValue(title);
    this.submitted = true;
    if (this.mailForm.invalid) {
      return;
    }
    this.sendMail = false;
    this.loading = true;
    this.api
      .post("rex/form/hubs-scale", this.mailForm.value)
      .pipe(first())
      .subscribe(
        (data) => {
          this.loading = false;
          this.submitted = false;
          this.mailForm.reset();
          this.sendMail = true;
          // alert(
          //   "Thanks your message has been sent and a property Speclialists will get back to you as soon as possible."
          // );
        },
        (error) => {
          this.loading = false;
        }
      );
  }
  get f() {
    return this.mailForm.controls;
  }
}
