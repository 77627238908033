import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  @Input() MenuToggle: boolean;
  MenuCollapsed: boolean = false;
  constructor(private api: ApiService) { }

  ngOnInit() {
    this.MenuCollapsed = this.MenuToggle;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['MenuToggle']) {
      this.MenuCollapsed = !this.MenuCollapsed;
    }
  }

}
