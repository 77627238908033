import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";

// DEP
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { NgSelectModule } from "@ng-select/ng-select";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { SidebarComponent } from './sidebar/sidebar.component';

@NgModule({
  declarations: [HeaderComponent, FooterComponent, SidebarComponent],
  imports: [
    RouterModule,
    CommonModule,
    CollapseModule, ModalModule.forRoot(), BsDropdownModule.forRoot(),
    NgSelectModule,
    SlickCarouselModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,SidebarComponent,
    CollapseModule, ModalModule, BsDropdownModule,
    NgSelectModule,
    SlickCarouselModule,
    FormsModule,
    ReactiveFormsModule,

    RecaptchaModule,
    RecaptchaFormsModule,
  ],

  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6LetJbEZAAAAALXA3FfTkrdNtvGs8hWyXQBYTorX',
      } as RecaptchaSettings,
    }
  ],
})
export class CommonSharedModule { }
