import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

// DEP
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ChartsModule } from "ng2-charts";
import { AgmCoreModule } from "@agm/core";



// shared modules
import { CommonSharedModule } from "./modules/frontend/common/common-shared.module";
// Auth
import { LoginComponent } from "./modules/auth/login/login.component";
import { ForgetpasswordComponent } from "./modules/auth/forgetpassword/forgetpassword.component";
import { ResetpasswordComponent } from "./modules/auth/resetpassword/resetpassword.component";

// Components
import { HomeComponent } from "./modules/frontend/components/home/home.component";

// Pages
import { AboutComponent } from "./modules/frontend/components/pages/about/about.component";
import { CareerComponent } from "./modules/frontend/components/pages/career/career.component";
import { SearchResultComponent } from "./modules/frontend/components/pages/search-result/search-result.component";
import { NewsInsightsComponent } from "./modules/frontend/components/pages/about/news-insights/news-insights.component";
import { NewsDetailComponent } from './modules/frontend/components/pages/about/news-detail/news-detail.component';
import { OurApproachComponent } from "./modules/frontend/components/pages/about/our-approach/our-approach.component";
import { OurStoryComponent } from "./modules/frontend/components/pages/about/our-story/our-story.component";
import { EmploymentOpportunitiesComponent } from './modules/frontend/components/pages/agents-pages/employment-opportunities/employment-opportunities.component';
import { ArticeDetailComponent } from "./modules/frontend/components/pages/about/artice-detail/artice-detail.component";
import { ArticlesComponent } from "./modules/frontend/components/pages/about/articles/articles.component";
import { CommonModule, HashLocationStrategy, LocationStrategy } from "@angular/common";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgetpasswordComponent,
    ResetpasswordComponent,

    HomeComponent,
    // pages
    AboutComponent,
    CareerComponent,
    SearchResultComponent,
    NewsInsightsComponent,
    OurApproachComponent,
    OurStoryComponent,
    NewsDetailComponent,
    EmploymentOpportunitiesComponent,
    ArticlesComponent,
    ArticeDetailComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ChartsModule,
    CommonSharedModule,
    AppRoutingModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyBMEJUjND8_hxqIkoVFMJmfabEa8D8iuzk"
    }),

  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
