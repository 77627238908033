import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { Article } from "./article";
import { IcentraService } from "src/app/services/icentra.service";

@Component({
  selector: "app-articles",
  templateUrl: "./articles.component.html",
  styleUrls: ["./articles.component.css"],
})
export class ArticlesComponent implements OnInit {
  article: Article[] = [];

  constructor(private ics: IcentraService) {}

  ngOnInit() {
    this.getArticle();
  }

  getArticle = () => {
    this.ics.getArticle().subscribe((res) => {
      this.article = res;
      this.article.forEach((res) => {
        res.image = res.image
          ? environment.S3 + "/" + res.image
          : "https://via.placeholder.com/220x120?text=Image+Not+Uploaded";
        res.text = res.description.replace(/<[^>]*>/g, " ");
      });
      this.article = this.article.filter((res) => res.site === "RS Resi");
    });
  };
}
