import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
//import { promise } from 'protractor';

@Injectable({
  providedIn: "root",
})
export class IcentraService {
  constructor(private http: HttpClient) {}

  loginIcentra() {
    this.http
      .post(`${environment.icentraApi}client/only-rs`, {})
      .subscribe((res) => {
        localStorage.setItem("ic_token", res["access_token"]);
      });
  }

  public getData() {
    return this.http.get(
      `${environment.icentraApi}client/users?sort=first_name&group_id=5&per_page=1000&include=offices,teams,userTestimonials,roles` 
    );
  }

  public getData_(callback) {
    this.http
    .get(
      `${environment.icentraApi}client/users?sort=first_name&group_id=5&per_page=1000&include=offices,teams,userTestimonials` 
    )
    .subscribe((res) => {
      callback(res);
    });
  }
  public externalApi(payload) {
    return this.http.post(
      `${environment.icentraApi}client/external-users`,
      payload
    ); 
  }

  public sendLeads(data) {
    return this.http.post(`${environment.icentraApi}client/external-Lead`, data);
  }

  public getArticle(): Observable<any> {
    return this.http.get(`${environment.icentraApi}client/site-blogs`);
  }

  public getDataPerPage(per_page, current_page) {
    return this.http.post(`${environment.icentraApi}client/external-users`, {
      per_page: per_page,
      current_page: current_page,
      include: "offices,teams,userTestimonials,roles",
    });
  }

  public getDetailArticle(id: any): Observable<any> {
    return this.http.get(`${environment.icentraApi}client/site-blogs/${id}`);
  }
}
