// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  icentraApi: 'https://live-api.icentra.com.au/v1/',
  // icentraApi: 'https://dev-backend.icentra.com.au/v1/',
  rsApi: 'https://rs.icentra.com.au/api/',
  recuAuth: 'https://auth.calendly.com/',
  recuApi: 'https://api.calendly.com/',
  S3: 'https://d733qf9v56ynw.cloudfront.net',


  // rsApi: 'http://localhost:8000/api/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
