import { ApiService } from 'src/app/api.service';
import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'site-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  MenuToggle: boolean = false;
  @Input() bg: boolean = false;
  constructor(private api: ApiService) { }
  ngOnInit() {

  }

  toggleMenu() {
    this.MenuToggle = !this.MenuToggle;
  }



}
