<header class="light-header">
  <nav class="desktop-front-navbar">
    <div class="container">
      <div class="navbar-inner d-flex justify-content-between">
        <div class="site-logo">
          <a routerLink="/">
            <img src="assets/img/RS-logo-blue.png" alt="">
          </a>
        </div>
        <ul class="navbar-nav">
          <li routerLinkActive="active">
            <a routerLinkActive="active" routerLink="/our-services">Our Services</a>
            <ul class="sub-menu">
              <li><a routerLinkActive="active" routerLink="/residential/sell-with-us">SELL WITH US</a>
              </li>
              <li><a routerLinkActive="active" routerLink="/residential/for-sale">FOR SALE</a></li>
              <li><a routerLinkActive="active" routerLink="/residential/sold-by-us">SOLD BY US</a></li>
              <li><a routerLinkActive="active" routerLink="/residential/for-lease">FOR LEASE</a></li>
            </ul>
          </li>

          <li>
            <a routerLinkActive="active" href="javascript:void(0);">Property Management</a>
            <ul class="sub-menu">
              <li>
                <a routerLinkActive="active" href="javascript:void(0);">Manage Property</a>
                <ul class="sub-menu">
                  <li><a routerLinkActive="active" routerLink="/property-management/manage-property">Manage My
                      property</a>
                  </li>
                  <li><a routerLinkActive="active" routerLink="/property-management/rental-appraisal">Rental
                      Appraisals</a>
                  </li>
                </ul>
              </li>
              <li>
                <a routerLinkActive="active" routerLink="/residential/for-lease">Our Properties For
                  Lease</a>
                <ul class="sub-menu">
                  <li><a routerLinkActive="active" routerLink="/residential/for-lease">Search for a
                      property</a></li>
                  <li><a routerLinkActive="active" routerLink="/properties-for-lease/application-form">Application
                      Forms</a></li>
                </ul>
              </li>
              <li>
                <a routerLinkActive="active" href="javascript:void(0);">I am a tenant</a>
                <ul class=" sub-menu">
                  <li><a routerLinkActive="active" routerLink="/tenant/induction">Induction</a></li>
                  <li><a routerLinkActive="active" routerLink="/tenant/portal">portal</a></li>
                  <li><a routerLinkActive="active" routerLink="/tenant/maintenance">Maintenance</a>
                  </li>
                  <li class="d-none"><a routerLinkActive="active" routerLink="/tenant/top-tips">Top
                      Tips for tenants</a></li>
                  <!-- <li><a routerLinkActive="active" routerLink="/tenant/payments">Payments</a></li> -->
                  <li><a routerLinkActive="active" routerLink="/tenant/vacating">Vacating</a></li>
                  <li><a routerLinkActive="active" routerLink="/tenant/request-form-documents">Request
                      forms & documents</a></li>
                </ul>
              </li>

              <li>
                <a routerLinkActive="active" href="javascript:void(0);">I am an owner</a>
                <ul class="sub-menu">
                  <li><a routerLinkActive="active" routerLink="/owner/portal">portal</a></li>
                  <li><a routerLinkActive="active" routerLink="/owner/maintenance">Maintenance</a>
                  </li>
                  <li><a routerLinkActive="active" routerLink="/owner/request-sales-appraisal">Request
                      a sales appraisal</a></li>
                </ul>
              </li>


            </ul>
          </li>

          <li><a routerLinkActive="active" href="http://realspecialistscommercial.com/">Commercial</a></li>

          <li>
            <a routerLinkActive="active" href="javascript:void(0);">OUR STORY</a>
            <ul class="sub-menu">
              <li><a routerLinkActive="active" routerLink="/about-us">About us</a></li>
              <li><a routerLinkActive="active" routerLink="/agents">OUR AGENTS</a></li>
              <li><a routerLinkActive="active" routerLink="/news">NEWS & INSIGHTS</a></li>
              <li><a routerLinkActive="active" routerLink="/our-approach">OUR APPROACH</a></li>
              <!-- <li><a routerLinkActive="active" routerLink="/rpm">RPM</a></li> -->
              <li><a routerLinkActive="active" routerLink="/articles">Articles</a></li>
            </ul>
          </li>

          <li>
            <a routerLinkActive="active" target="_blank" href="https://realforagents.com.au/">Join us</a>
            <!-- <ul class="sub-menu">
              <li><a routerLinkActive="active" routerLink="/join-as-agent">JOIN AS AN AGENT</a></li>
              <li><a routerLinkActive="active" routerLink="/join-as-agency">JOIN AS AN AGENCY</a></li>
              <li><a routerLinkActive="active" routerLink="/flexible-agent">FLEXIBLE AGENT MODELS</a></li>
              <li><a routerLinkActive="active" routerLink="/branding-and-equity">branding & equity</a>
              </li>
              <li><a routerLinkActive="active" routerLink="/hubs-and-scale">our hubs and scale</a></li>
              <li><a routerLinkActive="active" routerLink="/technologies-and-collaboration">Technologies &
                  Collaboration</a></li>
              <li><a routerLinkActive="active" routerLink="/employment-opportunities">
                  Employment Opportunities
                </a>
              </li>
            </ul> -->
          </li>

          <li>
            <a routerLinkActive="active">Contact</a>
            <ul class="sub-menu">
              <li><a routerLinkActive="active" routerLink="/contact">Get in Touch</a></li>
              <li><a routerLinkActive="active" routerLink="/agents">Our Agents</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <!-- Mobile Nav -->
  <nav class="navbar mobile-navbar">
    <div class="container">
      <div class="site-logo">
        <a routerLink="/">
          <img src="assets/img/RS-logo-blue.png" alt="">
        </a>
      </div>
      <button class="navbar-toggler menuTricker collapsed" [class.collapsed]="!MenuToggle" type="button"
        [attr.aria-expanded]="!MenuToggle" aria-controls="MenuToggle" (click)="toggleMenu()">
        <span></span>
        <span></span>
        <span></span>
      </button>
    </div>
  </nav>
</header>


<app-sidebar [MenuToggle]="MenuToggle"></app-sidebar>