<site-header></site-header>


<div class="home-banner bg" style="background:url('assets/img/banners/news-insights.jpg')no-repeat;">
  <div class="container">
    <div class="banner-content text-center">
      <h2>News & Insights</h2>
    </div>
  </div>
</div>
<!--===========================-->
<!-- News Categories Section   -->
<!--===========================-->
<div class="news-categories-section py-5 d-none">
  <div class="container">
    <div class="categories-body">
      <h2 class="mb-5">Categories</h2>
      <ul class="grid-4 list-unstyled d-flex flex-wrap" *ngIf="categories">
        <li *ngFor="let category of categories" class="bg"
          style="background:url('assets/img/categories/1.png')no-repeat;"
          [ngStyle]="{background: 'url(' + category.image + ')'}">
          <a href="javascript:void(0);" (click)="getPostsCategory(category.id)">
            <div class="category-content">
              <p>{{ category.name }}</p>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
<!--================-->
<!-- News Section   -->
<!--================-->
<div class="news-section py-5">
  <div class="container">
    <div class="categories-body">
      <h2>News & Insights</h2>
      <p>Subscribe to stay up to date with Real Specialists insider knowledge,
        insights & expertise on properties that interest you, before they hit the market.
      </p>
      <ul class="grid-4 list-unstyled d-flex flex-wrap news-list mt-4" *ngIf="isPost; else elseTemplate">
        <li *ngFor="let post of posts">
          <a href="javascript:void(0);" routerLink="/news/{{ post.id }}">
            <div class="news-thumbnail cursor-pointer" data-toggle="modal" data-target="#personal-details">

              <img src="{{ post.image }}" alt="">
            </div>
          </a>
          <div class="news-content">
            <h5><a href="">{{ post.title }}</a></h5>
            <p>{{ post.description }}…</p>
            <div class="action-meta">
              <a class="btn blue-outline btn-inline-flex mt-3 mb-2" href="javascript:void(0);"
                routerLink="/news/{{ post.id }}">
                <span class="pr-2">Read Article</span>
                <i class="x-icon icon-md-arrow-fill right-f mt-1"></i>
              </a>
            </div>
          </div>
        </li>
      </ul>
      <!-- Not Found -->
      <ng-template #elseTemplate>

        <h4 class="m-5 text-dummy text-center">No News Found.</h4>

      </ng-template>

      <div class="text-center mt-5 mb-4" *ngIf="posts && posts.lenght>0">
        <a href="javascript:void(0);" (click)="getMore()" sclass="btn blue-outline px-5">View More</a>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>



<!-- ============= -->
<!-- Model         -->
<!-- ============= -->
<div class="modal fade" id="personal-details" bsModal tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">

        <button type="button" class="close pull-right" aria-label="Close" data-dismiss="modal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="text-center px-4 mb-2">
          <h4>10 Ways to Make a Rental Home Your Own</h4>
        </div>
        <div class="widget-body">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut quo consequatur optio vel harum
            delectus eos recusandae, facere consequuntur quas. Laboriosam esse temporibus eum suscipit quis
            quaerat excepturi minus dolorem voluptas quos cupiditate expedita quasi, sed, aliquam soluta
            magni amet rem praesentium voluptatum deleniti ullam. Dolores nihil officia quia, dignissimos
            debitis commodi eos reprehenderit beatae cupiditate atque veritatis ratione vitae repellendus,
            provident, soluta non possimus cumque eligendi aliquid fuga. Nostrum voluptate libero debitis,
            repellendus quae porro aut voluptatibus quis eveniet ipsam optio ducimus! Non deleniti quo
            architecto possimus eligendi ipsum quia accusantium suscipit odio fuga cupiditate dolorum
            repellendus vel harum illo officia quidem eveniet, aperiam rerum quod earum beatae error
            officiis. Nisi, iure eligendi minus deserunt mollitia illum non consequuntur maxime cupiditate
            unde. Dignissimos voluptates eligendi deserunt rerum, veniam eveniet? Similique, ab officia nisi
            facilis dolor amet et voluptatem reiciendis impedit aperiam inventore, ipsa unde non. Nisi
            tenetur, corporis ducimus ut dolor modi, quos excepturi distinctio ipsa in ad quae. Nihil
            recusandae illo beatae voluptatem hic ad similique sequi, rerum blanditiis optio ullam nam!
            Distinctio iusto possimus asperiores, ut fugit ea voluptates, sunt rem voluptate vel ipsam esse
            voluptatem nesciunt a quisquam fuga beatae quas velit recusandae sequi reiciendis quos? Soluta
            fugit deleniti laborum aliquid ex cum iste ea praesentium iusto officiis voluptatum assumenda,
            necessitatibus rerum labore, atque aut! Beatae pariatur ab quae voluptas quo similique ad nisi
            impedit, in laborum eligendi. Distinctio impedit dolorem maxime tempora, nulla, atque earum
            velit laborum ratione dolorum tenetur dolores nemo voluptate ex, corporis ducimus eaque alias!
            Totam quaerat saepe sapiente obcaecati, voluptate laudantium sint et fuga unde aperiam dolorem
            impedit vitae praesentium nisi, facere, veritatis tempore. Repellendus vitae praesentium
            pariatur cumque. Dignissimos nostrum suscipit eos dolorem, nam natus eum magni non fugit fugiat,
            qui rerum incidunt itaque beatae ipsum excepturi tenetur ipsam, hic quisquam sapiente alias
            reiciendis est aliquam. Facere sint deserunt dolorum tenetur quisquam laboriosam velit,
            provident magni aliquid quia accusantium molestias, praesentium veritatis minima rem ut
            molestiae itaque nostrum perspiciatis? Aliquam est hic delectus rem cum minus vero sequi
            voluptatum quas impedit, provident dolorum modi ab veritatis, velit fuga aut nobis blanditiis.
            Alias deserunt placeat quas id unde perferendis fugiat quidem reprehenderit et? Natus culpa iste
            neque, est excepturi debitis, at tenetur enim eum vel, tempore assumenda. Sint numquam
            laboriosam voluptate, eveniet molestias reiciendis non quas quisquam aperiam, consectetur
            impedit voluptatem ipsum ullam tenetur odit. Vel similique consectetur blanditiis error velit?
          </p>
        </div>
      </div>
    </div>
  </div>
</div>