<site-header></site-header>

<div class="home-banner bg" style="background:url('assets/img/banners/our-story.jpg')no-repeat;">
  <div class="container">
    <div class="banner-content text-center">
      <h2>Our Story</h2>
    </div>
  </div>
</div>
<!--======================-->
<!-- RS Stories Section   -->
<!--======================-->
<div class="rs-stories-section">
  <div class="container">
    <div class="rs-stories-body">
      <div class="rs-story" style="background:url('assets/img/our-story/story-5.jpg')no-repeat right center;">
        <div class="rs-story-content">
          <h2>Culture</h2>
          <p>
            We’ve recognised that the market is ready for a fresh participant
            built upon a culture of collaboration and we aim to use our
            experience to develop a community that features the support and
            leadership structures necessary to empower our teams, partners and
            customers.
          </p>
        </div>
      </div>
      <div class="rs-story" style="background:url('assets/img/our-story/story-6.jpg')no-repeat left center;">
        <div class="rs-story-content ml-auto">
          <h2>Commitment</h2>
          <p>
            We want to establish a new approach to real estate, where each of
            our specialists is their own boss and they are committed to
            anticipating your needs, nurturing relationships and becoming your
            lifelong property partner.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!--===============================-->
<!-- Innovative Marketing Section   -->
<!--===============================-->
<div class="innovative-maketing-section">
  <div class="bg-grey over-bottom-area"></div>
  <div class="container">
    <div class="innovative-header pt-4">
      <div class="row align-items-end">
        <div class="col-lg-5 col-md-12 mb-md-5 mb-3">
          <h4>A brokerage of <br> innovators <br> and specialists </h4>
        </div>
        <div class="col-lg-7 col-md-12 mb-md-5 mb-3">
          <p>We believe that selling property is more than just a transaction-
            it's an experience. That's why our talented team will spare no
            effort in making sure that your experience is as simple and smooth
            as possible. Throughout the entire process, you can look forward to
            a selling experience that is unlike any other.</p>
        </div>
      </div>
    </div>
    <div class="innovative-maketing-overlay">
      <div class="video">
        <img src="assets/img/our-story/innovative-marketing.png" alt="" class="img-fluid">
      </div>
    </div>

    <div class="real-specialist text-center mt-5">
      <img src="assets/img/realspecialist-2.png" alt="" class="img-fluid" />
      <p>
        Whether you are interested in residential or commercial, we aim to
        simplify the real estate process, one market at a time.
      </p>
    </div>

  </div>
</div>
<!--======================-->
<!-- RS Stories Section   -->
<!--======================-->
<div class="rs-stories-section bg-grey">
  <div class="container">
    <div class="rs-stories-body">
      <div class="rs-story" style="background:url('assets/img/our-story/story-1.png')no-repeat right center;">
        <div class="rs-story-content">
          <h2>Creating meaningful experiences through innovative technology</h2>
          <p>
            Through our proprietary technologies and people-first mindset, we're
            uncovering deeper industry insights and streamlining how our
            specialists generate value for our clients. We’re looking to create
            a community that’s more people-focused by putting our customers and
            teams at the forefront of everything that we do.
          </p>
        </div>
      </div>
      <div class="rs-story" style="background:url('assets/img/our-story/story-2.png')no-repeat left center;">
        <div class="rs-story-content ml-auto">
          <h2>A new approach to real estate</h2>
          <p>
            We’re looking to shift the traditional real estate model to be more
            people-focused and consumer-centric. Whilst traditional agencies
            have largely been static hierarchies, our approach takes our agents
            and turns them into self-contained, independent organisations that
            act as partner businesses rather than employed agents. This means
            that we’re able to give our specialists the freedom and independence
            needed to generate greater value for our customers.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<!--==========================-->
<!-- RS Slide-Agents Section  -->
<!--==========================-->

<div class="rs-slide-agents-section py-5">
  <div class="container py-md-5">
    <div class="sa-header my-4 d-flex align-items-center flex-column flex-sm-row justify-content-between">
      <h2 class="mb-sm-0 mb-3">Step inside Real Specialists</h2>
      <a href="javascript:void(0);" routerLink="/agents" class="btn blue-outline btn-inline-flex slide-hvr">
        <span>Meet Our Agents</span>
        <i class="x-icon icon-md-arrow-fill right-f"></i>
      </a>
    </div>
    <div class="sa-body">
      <ngx-slick-carousel class="carousel sa-slider" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem class="slide" *ngFor="let u of users">
          <div class="agent-thumbnail">
            <img [src]="u.photo" alt="" />
          </div>
          <div class="agent-content">
            <h4>{{u.first_name + ' ' + u.last_name}}</h4>
            <p>{{u.offices.data[0].name}} Office</p>
          </div>
        </div>

        <!-- <div ngxSlickItem class="slide">
          <div class="agent-thumbnail">
            <img src="assets/img/agents/agent-1.png" alt="" />
          </div>
          <div class="agent-content">
            <h4>Jared Hodge</h4>
            <p>Casuarina Office</p>
          </div>
        </div>
        <div ngxSlickItem class="slide">
          <div class="agent-thumbnail">
            <img src="assets/img/agents/agent-2.png" alt="" />
          </div>
          <div class="agent-content">
            <h4>David Manby</h4>
            <p>Robina Office</p>
          </div>
        </div>
        <div ngxSlickItem class="slide">
          <div class="agent-thumbnail">
            <img src="assets/img/agents/agent-3.png" alt="" />
          </div>
          <div class="agent-content">
            <h4>Raymond Pienaar</h4>
            <p>Burleigh Office</p>
          </div>
        </div>
        <div ngxSlickItem class="slide">
          <div class="agent-thumbnail">
            <img src="assets/img/agents/agent-4.png" alt="" />
          </div>
          <div class="agent-content">
            <h4>Rosina Ayaz</h4>
            <p>Varsity Lakes Office</p>
          </div>
        </div>
        <div ngxSlickItem class="slide">
          <div class="agent-thumbnail">
            <img src="assets/img/agents/agent-1.png" alt="" />
          </div>
          <div class="agent-content">
            <h4>Jared Hodge</h4>
            <p>Casuarina Office</p>
          </div>
        </div>
        <div ngxSlickItem class="slide">
          <div class="agent-thumbnail">
            <img src="assets/img/agents/agent-2.png" alt="" />
          </div>
          <div class="agent-content">
            <h4>David Manby</h4>
            <p>Robina Office</p>
          </div>
        </div>
        <div ngxSlickItem class="slide">
          <div class="agent-thumbnail">
            <img src="assets/img/agents/agent-3.png" alt="" />
          </div>
          <div class="agent-content">
            <h4>Raymond Pienaar</h4>
            <p>Burleigh Office</p>
          </div>
        </div>
        <div ngxSlickItem class="slide">
          <div class="agent-thumbnail">
            <img src="assets/img/agents/agent-4.png" alt="" />
          </div>
          <div class="agent-content">
            <h4>Rosina Ayaz</h4>
            <p>Varsity Lakes Office</p>
          </div>
        </div> -->
      </ngx-slick-carousel>

      <div class="agents-slider-arrow">
        <a href="javascript:void(0);" class="slider-arrow-outline blue-outline" (click)="next()">
          <i class="x-icon icon-sm-arrow-fill"></i>
        </a>
        <a href="javascript:void(0);" class="slider-arrow-outline blue-outline sm" (click)="prev()">
          <i class="x-icon icon-md-arrow-fill"></i>
        </a>
      </div>
    </div>
  </div>
</div>
<!--=========-->
<!-- Footer  -->
<!--=========-->
<app-footer></app-footer>