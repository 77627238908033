<site-header></site-header>


<div class="home-banner bg" *ngIf="article && article.image" [ngStyle]="{background: 'url(' + article.image + ')'}">
	<div class="container">
		<div class="banner-content text-center">
			<h2 *ngIf="article && article.title">{{ article.title }}</h2>
		</div>
	</div>
</div>


<div class="news-details-section py-5">
	<div class="container py-md-5" [innerHTML]="article?.description">
	</div>
</div>

<app-footer></app-footer>