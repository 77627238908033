<site-header></site-header>


<div class="home-banner bg" style="background:url('assets/img/banners/news-insights.jpg')no-repeat;">
    <div class="container">
        <div class="banner-content text-center">
            <h2>Articles</h2>
        </div>
    </div>
</div> 
<!--================-->
<!-- Articles Section   -->
<!--================-->
<div class="news-section py-5">
    <div class="container">
        <div class="categories-body">
            <h2>Articles</h2>
            <p>Subscribe to stay up to date with Real Specialists insider knowledge,
                Articles & expertise on properties that interest you, before they hit the market.
            </p>
            <ul class="grid-4 list-unstyled d-flex flex-wrap news-list mt-4" *ngIf="article && article.length > 0">
                <li *ngFor="let post of article">
                    <a href="javascript:;" routerLink="/article/{{ post.id }}">
                        <div class="news-thumbnail cursor-pointer" data-toggle="modal" data-target="#personal-details">
                            <img src="{{ post.image }}" alt="">
                        </div>
                    </a>
                    <div class="news-content">
                        <h5><a routerLink="/article/{{ post.id }}">{{ post.title }}</a></h5>
                        <p>{{post.text | slice:0:70}} ...</p>
                        <div class="action-meta">
                            <a class="btn blue-outline btn-inline-flex mt-3 mb-2" href="javascript:;"
                                routerLink="/article/{{ post.id }}">
                                <span class="pr-2">Read Article</span>
                                <i class="x-icon icon-md-arrow-fill right-f mt-1"></i>
                            </a>
                        </div>
                    </div>
                </li>
            </ul>
            <ng-template #elseTemplate>
                <h4 class="m-5 text-dummy text-center">No News Found.</h4>
            </ng-template>

            
        </div>
    </div>
</div>

<app-footer></app-footer>