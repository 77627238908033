<site-header></site-header>

<div class="home-banner  position-relative bg" style="background:url('assets/img/banners/our-hubs.jpg')no-repeat;">
  <div class="banner-overlay"></div>
  <div class="container position-relative ">
    <div class="banner-content text-center">
      <h2>Employment Opportunities</h2>
    </div>
  </div>
</div>
<!--=============================-->
<!-- RS opportunities  Section   -->
<!--=============================-->
<div class="rs-opportunity-section">
  <div class="container py-5">
    <h2>Discover Opportunities at Real Specialists</h2>
    <div class="row">
      <div class="col-md-4">
        <div class="s-rs-opportunity">
          <h3>Agent Teams </h3>
          <p>12 open roles</p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="s-rs-opportunity">
          <h3>Marketing & Creative</h3>
          <p>6 open roles</p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="s-rs-opportunity">
          <h3>People & Culture </h3>
          <p>2 open roles</p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="s-rs-opportunity">
          <h3>Technology</h3>
          <p>3 open roles</p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="s-rs-opportunity">
          <h3>Finance</h3>
          <p>1 open roles</p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="s-rs-opportunity">
          <h3>Sales & Business Development</h3>
          <p>4 open roles</p>
        </div>
      </div>
    </div>
    <div class="text-left mt-4 mb-5">
      <a class="btn mt-4 blue-outline btn-inline-flex slide-hvr" href="javascript:void(0);"
        (click)="staticModal.show()">
        <span>Start exploring</span>
        <i class="x-icon icon-md-arrow-fill ml-3 mt-1 right-f"></i>
      </a>
    </div>
  </div>
</div>
<!--=========-->
<!-- Footer  -->
<!--=========-->
<app-footer></app-footer>

<div class="modal fade" bsModal #staticModal="bs-modal" tabindex="-1" role="dialog"
  aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="staticModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="text-center px-4 mb-2">
          <!-- <h4>Would you like one of our Property Specialists to contact you about this property?</h4> -->
        </div>
        <div class="widget-body">
          <form [formGroup]="mailForm" (ngSubmit)="onSubmit('Hub Scale')" class="enquiry-form" *ngIf="!sendMail">

            <div class="form-group">
              <input type="text" placeholder="First Name" class="site-input" name="name" autocomplete="name"
                formControlName="name" required [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
              <!-- check -->
              <div *ngIf="submitted && f.name.errors" class="invalid-feedback d-block">
                <div *ngIf="f.name.errors.required">
                  Name is required
                </div>
              </div>
            </div>
            <div class="form-group">
              <input type="text" placeholder="LastName" class="site-input" name="name" autocomplete="name"
                placeholder="Last Name">
            </div>

            <div class="form-group">
              <input type="email" name="email" autocomplete="email" formControlName="email" required placeholder="Email"
                class="site-input">
              <!-- check -->
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback d-block">
                <div *ngIf="f.email.errors.required">
                  Email is required
                </div>
                <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
              </div>
            </div>

            <div class="form-group">
              <input type="file" name="mobile" autocomplete="mobile" formControlName="resume"
                (change)="onSelectFile($event)" class="site-input">
            </div>
            <div class="form-group">
              <input type="tel" name="mobile" autocomplete="mobile" formControlName="mobile" required
                placeholder="Phone" class="site-input">
              <!-- check -->
              <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback d-block">
                <div *ngIf="f.mobile.errors.required">
                  Mobile is required
                </div>
              </div>
            </div>
            <div class="form-group">
              <input type="url" name="linkedin_profile" autocomplete="mobile" formControlName="linkedin_profile"
                required placeholder="Linkedin Profile" class="site-input">
              <!-- check -->
              <div *ngIf="submitted && f.linkedin_profile.errors" class="invalid-feedback d-block">
                <div *ngIf="f.linkedin_profile.errors.required">
                  Linkedin Profile is required
                </div>
              </div>
            </div>
            <div class="form-group">
              <input type="url" name="linkedin_profile" autocomplete="mobile" formControlName="website" required
                placeholder="Website" class="site-input">
              <!-- check -->
              <div *ngIf="submitted && f.website.errors" class="invalid-feedback d-block">
                <div *ngIf="f.website.errors.required">
                  Website is required
                </div>
              </div>
            </div>

            <div class="form-group">
              <textarea class="site-input" name="message" autocomplete="message" formControlName="message"
                placeholder="How did you hear about this job?" required></textarea>
              <!-- check -->
              <div *ngIf="submitted && f.message.errors" class="invalid-feedback d-block">
                <div *ngIf="f.message.errors.required">
                  Message is required
                </div>
              </div>
            </div>

            <div class="form-group">
              <re-captcha formControlName="recaptchaReactive"></re-captcha>
            </div>


            <div class="form-group">
              <input type="submit" [disabled]="mailForm.invalid" value="Submit"
                class="btn btn-theme-blue slide-hvr w-100">
            </div>

          </form>

          <div class="alert alert-success" *ngIf="sendMail">
            <strong>Success!</strong> Thanks your message has been sent and a property Speclialists will get
            back to
            you as soon as possible.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>