<site-header></site-header>


<div class="home-banner bg" [ngStyle]="{background: 'url(' + image + ')'}">
  <div class="bg-overlay"></div>
  <div class="container z-10">
    <div class="banner-content text-center">
      <h2>{{ title }}</h2>
    </div>
  </div>
</div>


<div class="news-details-section py-5">
  <div class="container py-md-5">
    <div [innerHTML]="post?.content"></div>
  </div>
</div>

<app-footer></app-footer>