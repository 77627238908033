<site-header></site-header>

<div class="home-banner bg" style="background:url('assets/img/banners/about-us.jpg')no-repeat;">
  <div class="container">
    <div class="banner-content text-center">
      <h2>About Us</h2>
    </div>
  </div>
</div>
<!--==============================-->
<!-- Qoutes Section               -->
<!--==============================-->
<div class="qoute-section">
  <div class="container">
    <div class="quote-body">
      <i class="x-icon icon-quote-blue"></i>
      <h2>
        We believe that there is a better way to do Real Estate. A more valuable, less rigid way where customers are
        earned rather than bought and our specialists are given the support necessary to achieve it.
      </h2>
      <p>We're passionate about it, and our mission is to help people achieve it. </p>
      <div class="text-center"><span class="while-line"></span></div>
      <div class="text-center"><b>Jared Hodge</b></div>
      <div class="text-center"><span>REAL SPECIALISTS DIRECTOR</span></div>
    </div>
  </div>
</div>
<!--===============================-->
<!-- Innovative Marketing Section   -->
<!--===============================-->
<div class="innovative-maketing-section pt-5 pb-0 pb-md-5">
  <div class="bg-grey over-bottom-area"></div>
  <div class="container">
    <div class="innovative-header">
      <div class="row align-items-end justify-content-center">
        <div class="col-md-9 mb-md-5 mb-4 text-center">
          <h2>Our Culture of Collaboration</h2>
          <p>
            When you work with one of our specialists, you aren't just working with a single broker, you're hiring a
            vast network of dedicated real estate professionals committed to achieving the best results for you.
            <br>
            <br>
            We're working to create a company culture that sustains an independent and empowered workforce where our
            specialists are more like business partners rather than employees. We want our specialists to have
            opportunities to help small businesses grow, share their work, as well as gain recognition under their own
            brand.
            <br>
            <br>
            We believe that our innovative spirit and pioneering attitude makes us stand out within the industry and
            we’re building a more diverse and equitable organisation for our teams, customers, and wider community. We
            are empowering each other to bring unique perspectives and experiences to work, and we continually look for
            new ways to make it possible.
          </p>
        </div>
      </div>
    </div>
    <div class="innovative-maketing-overlay">
      <div class="video">
        <img src="assets/img/approaches/innovative-marketing.png" alt="" class="img-fluid">
      </div>
    </div>
  </div>
</div>

<!--==========================-->
<!-- RS Records Info Section  -->
<!--==========================-->
<div class="rs-records-info">
  <div class="container">
    <div class="row">
      <div class="rs-records-single">
        <h2>13,000</h2>
        <p>Listings per year</p>
      </div>
      <!-- <div class="right-border-wrapper">
        <div class="right-border"></div>
      </div> -->
      <div class="rs-records-single">
        <h2>10+</h2>
        <p>Average team <br> member experience</p>
      </div>
      <!-- <div class="right-border-wrapper">
        <div class="right-border d-none d-md-block"></div>
      </div> -->
      <div class="rs-records-single">
        <h2>4</h2>
        <p>Days between tenants</p>
      </div>
      <!-- <div class="right-border-wrapper">
        <div class="right-border"></div>
      </div> -->
      <div class="rs-records-single">
        <h2>351,000</h2>
        <p>Enquiries per year</p>
      </div>
    </div>
  </div>
</div>
<!--==========================-->
<!-- RS Slide-Agents Section  -->
<!--==========================-->
<div class="rs-slide-agents-section py-5">
  <div class="container py-md-5">
    <div class="sa-header my-4 d-flex align-items-center flex-column flex-sm-row justify-content-between">
      <h2 class="mb-sm-0 mb-3">Meet our Executive Team</h2>
      <a href="javascript:void(0);" routerLink="/agents" class="btn blue-outline btn-inline-flex slide-hvr">
        <span>Meet Our Agents</span>
        <i class="x-icon icon-md-arrow-fill right-f"></i>
      </a>
    </div>
    <div class="sa-body">
      <ngx-slick-carousel class="carousel sa-slider" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem class="slide" *ngFor="let u of users">
          <div class="agent-thumbnail"
            [ngStyle]="{background: u.photo ? 'url(' + u.photo + ')no-repeat' :  'url(assets/img/dummy-agent.jpg)no-repeat'}">
            <a href="javascript:void(0);" routerLink="/agents/new-agent/{{ u.username }}"></a>
          </div>
          <div class="agent-content">
            <h4>{{u.first_name + ' ' + u.last_name}}</h4>
            <p>Real Specialists</p>
          </div>
        </div>
      </ngx-slick-carousel>
      <div class="agents-slider-arrow text-center mt-5">
        <a href="javascript:void(0);" class="slider-arrow-outline blue-outline" (click)="next()">
          <i class="x-icon icon-sm-arrow-fill"></i>
        </a>
        <a href="javascript:void(0);" class="slider-arrow-outline blue-outline right-arrow" (click)="prev()">
          <i class="x-icon icon-sm-arrow-fill"></i>
        </a>
      </div>
    </div>
  </div>
</div>
<!--=========-->
<!-- Footer  -->
<!--=========-->
<app-footer></app-footer>