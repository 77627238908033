import { Component, OnInit, ViewChild } from '@angular/core';
import { IcentraService } from "../../../../../services/icentra.service";
import { SlickCarouselComponent } from "ngx-slick-carousel";
@Component({
  selector: "app-about",
  templateUrl: "./about.component.html",
  styleUrls: ["./about.component.css"]
})
export class AboutComponent implements OnInit {
  @ViewChild('slickModal', { static: true }) slickModal: SlickCarouselComponent;
  currentDotActive = 1;
  sliderbar = 50;
  lastActive = false;
  listedUsers = [];
  users = [];


  constructor(
    private IC: IcentraService,
  ) {}

  ngOnInit() {
    this.IC.getData().subscribe(re => {
      this.users = re["data"];
      let arr = [];
      arr.push(this.users.filter(e => e.id == 212)[0]);
      arr.push(this.users.filter(e => e.id == 211)[0]);
      arr.push(this.users.filter(e => e.id == 210)[0]);
      arr.push(this.users.filter(e => e.id == 156)[0]);
      arr.push(this.users.filter(e => e.id == 181)[0]);
      // this.users = this.users.filter(e => e.id == 24 || e.id==210 || e.id==211 || e.id==212 || e.id==181 || e.id==156);
      this.users = arr;
    });
    }
  // Agent Slider
  slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 1500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  next() {
    this.slickModal.slickNext();
  }
  prev() {
    this.slickModal.slickPrev();
  }

}