import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NgSelectModule } from '@ng-select/ng-select';

import { HomeComponent } from './modules/frontend/components/home/home.component';
import { SearchResultComponent } from './modules/frontend/components/pages/search-result/search-result.component';
// about us
import { NewsInsightsComponent } from './modules/frontend/components/pages/about/news-insights/news-insights.component';
import { OurApproachComponent } from './modules/frontend/components/pages/about/our-approach/our-approach.component';
import { OurStoryComponent } from './modules/frontend/components/pages/about/our-story/our-story.component';
import { AboutComponent } from './modules/frontend/components/pages/about/about.component';
import { NewsDetailComponent } from './modules/frontend/components/pages/about/news-detail/news-detail.component';
import { EmploymentOpportunitiesComponent } from './modules/frontend/components/pages/agents-pages/employment-opportunities/employment-opportunities.component';
import { ArticlesComponent } from './modules/frontend/components/pages/about/articles/articles.component';
import { ArticeDetailComponent } from './modules/frontend/components/pages/about/artice-detail/artice-detail.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'search',
    component: SearchResultComponent
  },
  {
    path: 'our-story',
    component: OurStoryComponent
  },
  {
    path: 'about-us',
    component: AboutComponent
  },
  {
    path: 'news',
    component: NewsInsightsComponent
  },
  {
    path: 'news/:id',
    component: NewsDetailComponent
  },
  {
    path: 'articles',
    component: ArticlesComponent
  },
  {
    path: 'article/:id',
    component: ArticeDetailComponent
  },
  {
    path: 'our-approach',
    component: OurApproachComponent
  },
  {
    path: 'employment-opportunities',
    component: EmploymentOpportunitiesComponent
  },
  {
    path: 'residential',
    loadChildren: () =>
      import(
        './modules/frontend/components/residential/residential.module'
      ).then(mod => mod.ResidentialModule)
  },
  {
    path: 'agents',
    loadChildren: () =>
      import('./modules/frontend/components/agents/agents.module').then(
        mod => mod.AgentsModule
      )
  },
  // JOIN US pages
  {
    path: 'join-as-agent',
    loadChildren: () =>
      import('./modules/frontend/components/agents/join-agent/join-agent.module').then(
        mod => mod.JoinAsAgentsModule
      )
  },
  {
    path: 'flexible-agent',
    loadChildren: () =>
      import('./modules/frontend/components/agents/flexible-agent-model/flexible-agent-model.module').then(
        mod => mod.FlexibleAgentModule
      )
  },
  {
    path: 'branding-and-equity',
    loadChildren: () =>
      import('./modules/frontend/components/pages/agents-pages/branding-opportunities/branding-opportunities.module').then(
        mod => mod.BrandingOpportunitiesModule
      )
  },
  {
    path: 'hubs-and-scale',
    loadChildren: () =>
      import('./modules/frontend/components/pages/agents-pages/hubs-scale/hubs-scale.module').then(
        mod => mod.HubsScaleModule
      )
  },
  {
    path: 'technologies-and-collaboration',
    loadChildren: () =>
      import('./modules/frontend/components/pages/agents-pages/technologies/technologies.module').then(
        mod => mod.TechnologiesModule
      )
  },
  {
    path: 'support-leadership',
    loadChildren: () =>
      import('./modules/frontend/components/pages/agents-pages/support-leadership/support-leadership.module').then(
        mod => mod.SupportLeadershipModule
      )
  },
  {
    path: 'join-as-agency',
    loadChildren: () =>
      import('./modules/frontend/components/pages/agents-pages/join-as-agency/join-as-agency.module').then(
        mod => mod.JoinAsAgencyModule
      )
  },

  /*
  *
  * @Properties
  */
  {
    path: 'property',
    loadChildren: () =>
      import('./modules/frontend/components/properties/properties.module').then(
        mod => mod.PropertiesModule
      )
  },
  /*
   * 02
   * @Property Management
   */
  {
    path: 'property-management',
    loadChildren: () =>
      import('./modules/frontend/components/property-management-pages/property-management/property-management.module').then(
        mod => mod.PropertyManagementModule
      )
  },

  {
    path: 'rental-property-management',
    loadChildren: () =>
      import('./modules/rpm/rpm.module').then(
        mod => mod.RpmModule
      )
  },

  /*
  *
  * @iam-tenant
  */
  {
    path: 'tenant',
    loadChildren: () =>
      import('./modules/frontend/components/property-management-pages/iam-tenant/iam-tenant.module').then(
        mod => mod.IamTenantModule
      )
  },
  /*
  *
  * @iam-owner
  */
  {
    path: 'owner',
    loadChildren: () =>
      import('./modules/frontend/components/property-management-pages/iam-owner/iam-owner.module').then(
        mod => mod.IamOwnerModule
      )
  },
  /*
  *
  * @ Propeties for lease
  */
  {
    path: 'properties-for-lease',
    loadChildren: () =>
      import('./modules/frontend/components/property-management-pages/our-property-lease/our-property-lease.module').then(
        mod => mod.OurPropertyLeaseModule
      )
  },



  /*
  *
  * @Contact Footer Pages
  */
  {
    path: 'contact',
    loadChildren: () =>
      import('./modules/frontend/components/pages/contact/contact.module').then(
        mod => mod.ContactModule
      )
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./modules/frontend/components/pages/footer-pages/privacy-policy/privacy-policy.module').then(
        mod => mod.PrivacyPolicyModule
      )
  },
  {
    path: 'terms-conditions',
    loadChildren: () =>
      import('./modules/frontend/components/pages/footer-pages/terms-conditions/terms-conditions.module').then(
        mod => mod.TermsConditionsModule
      )
  },
  {
    path: 'refund-policy',
    loadChildren: () =>
      import('./modules/frontend/components/pages/footer-pages/refund-policy/refund-policy.module').then(
        mod => mod.RefundPolicyModule
      )
  },


  /*
  *
  *
  * @Recruitment pages
  * 
  */
  // {
  //   path: 'recruitment',
  //   loadChildren: () =>
  //     import('./modules/recruitment/recruitment.module').then(
  //       mod => mod.RecruitmentModule
  //     )
  // },
  /*
    *
    *
    * @OnBoarding pages
    * 
    */
  {
    path: 'onboarding',
    loadChildren: () =>
      import('./modules/onboarding/onboarding.module').then(
        mod => mod.OnboardingModule
      )
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }), NgSelectModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
