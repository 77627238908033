import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../../../../../api.service";
import { ActivatedRoute } from "@angular/router";
@Component({
  selector: "app-news-detail",
  templateUrl: "./news-detail.component.html",
  styleUrls: ["./news-detail.component.css"],
})
export class NewsDetailComponent implements OnInit {
  id;
  post: any;
  image = "assets/img/banners/news-insights.jpg";
  title = "";
  constructor(
    private api: ApiService,
    private activatedRoute: ActivatedRoute
  ) {}
  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.id = params["id"];
    });
    this.getPost();
  }

  getPost() {
    this.api.get("blog/post/" + this.id).subscribe((res) => {
      this.post = res.data;
      this.image = res.data.image;
      this.title = res.data.title;
    });
  }
}
