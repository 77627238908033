import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.css']
})
export class SearchResultComponent implements OnInit {

  isFocused = false;
  selectedProp;
  propType;
  propBathrooms;
  propParking;
  propPrice;
  propPriceHigh;
  propFilers;
  constructor() { }

  ngOnInit() {
  }

  // Thumbnails Slider
  slideConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "arrows": true,
    "autoplay": false,
    "autoplaySpeed": 3000,
    "speed": 1000
  };

  // Dummy Data
  // Testing Purpose
  prop_parts = [
    {
      id: 1,
      type: 'House',
      bathrooms: 5,
      parking: 2,
      price: '$10.000',
      filter: 'Filter 1',
      avatar: './assets/img/icons/bed.png'
    },
    {
      id: 2,
      type: 'Flat',
      bathrooms: 4,
      parking: 2,
      price: '$30.000',
      filter: 'Filter 2',
      avatar: './assets/img/icons/bed.png'
    },
    {
      id: 3,
      type: 'Plat',
      bathrooms: 2,
      parking: 1,
      price: '$40.000',
      filter: 'Filter 3',
      avatar: './assets/img/icons/bed.png'
    },
    {
      id: 4,
      type: 'House',
      bathrooms: 5,
      parking: 3,
      price: '$90.000',
      filter: 'Filter 4',
      avatar: './assets/img/icons/bed.png'
    },

  ];

}
