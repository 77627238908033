import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IcentraService } from "src/app/services/icentra.service";
import { environment } from "src/environments/environment";
import { Article } from "../articles/article";

@Component({
  selector: "app-artice-detail",
  templateUrl: "./artice-detail.component.html",
  styleUrls: ["./artice-detail.component.css"],
})
export class ArticeDetailComponent implements OnInit {
  id: any;
  image = "assets/img/banners/news-insights.jpg";
  title = "";
  article: Article;
  constructor(
    private api: IcentraService,
    private activatedRoute: ActivatedRoute
  ) {}
  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.id = params["id"];
      this.getPost(this.id);
    });
  }

  getPost(id: any) {
    this.api.getDetailArticle(id).subscribe((res) => {
      this.article = res;

      this.article.image = this.article.image
        ? environment.S3 + "/" + this.article.image
        : "https://via.placeholder.com/220x120?text=Image+Not+Uploaded";
    });
  }
}
