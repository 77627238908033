import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../../../../../api.service";

@Component({
  selector: 'app-news-insights',
  templateUrl: './news-insights.component.html',
  styleUrls: ['./news-insights.component.css']
})
export class NewsInsightsComponent implements OnInit {
  isPost = false;
  limit = 8;
  category = '';
  categories: any;
  posts: any;

  constructor(private api: ApiService) { }

  ngOnInit() {
    this.getCategories();
    this.getPosts();
  }

  getCategories() {
    this.api
      .get("blog/categories")
      .subscribe(res => {
        this.categories = res.data;
      });

  }

  getPosts() {
    this.isPost = false;
    this.api
      .get("blog/posts", {
        limit: this.limit,
        category: this.category,
        published: 1
      })
      .subscribe(res => {
        if (res.data) {
          this.posts = res.data;
          this.isPost = true;
        }
      });

  }

  getMore() {
    this.limit = this.limit + 8;
    this.getPosts();
  }
  getPostsCategory(category) {
    this.category = category;
    this.getPosts();
  }

}
